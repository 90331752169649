import { extendTheme, textDecoration, withDefaultColorScheme } from "@chakra-ui/react";

export const colors = {
  brand: {
    white: "#ffffff",
    black: "#000000",
    gray: {
      100: "#f8f8f8", // grey-ligher
      300: "#f5f5f5", // grey-light
      500: "#707070", // grey
      700: "#464646", // grey-dark
      900: "#323232", // grey-darker
    },
    green: "#20a22a",
    red: "#7c0000",
    line: "#adadad",
    sand: "#e8e3dc",
    lightSand: "#F6F3E6",
  },
  studio: {
    white: "#FFFFFF",
    black: "#000000",
    gray: { 300: "#EEEEEE", 500: "#C8C8C8", 700: "#3C3C3C" },
    teal: "#1A4842",
    tealTint: "#EDF1F0",
    night: "#1C355E",
    nightTint: "#E9EBEF",
  },
};
const transition = {
  duration: {
    imageHover: "1s",
  },
  timing: {
    imageHover: "cubic-bezier(0.1, 0.6, 0.4, 1)",
  },
};

const fonts = {
  heading: "BasisGrotesquePro, Ariel, sans-serif",
  body: "BasisGrotesquePro, Ariel, sans-serif",
  japaneese: "NotoSansJPregular, sans-serif",
};

const components = {
  Heading: {
    baseStyle: {
      fontWeight: 300,
    },
    variants: {
      title: {
        textTransform: "uppercase",
      },
      heading1: {
        fontSize: () => ({ base: "4xl", md: "5xl" }),
        fontWeight: 400,
        lineHeight: "100%",
      },
      heading2: {
        fontSize: () => ({ base: "2xl", md: "3xl" }),
        fontWeight: 400,
        lineHeight: "130%",
      },
      heading3: {
        fontSize: () => ({ base: "lg", md: "2xl" }),
        fontWeight: 400,
        lineHeight: "140%",
      },
    },
  },
  Text: {
    baseStyle: {
      fontSize: "sm",
      fontWeight: 300,
      lineHeight: "150%",
    },
    variants: {
      teaser: {
        fontSize: "13px",
        lineHeight: "150%",
      },
      navigation: {
        fontSize: "11px",
        lineHeight: "150%",
      },
    },
  },
  Form: {
    variants: {
      floating: {
        container: {
          _focusWithin: {
            label: {
              transform: "scale(0.6) translateY(-12px)",
            },
          },
          "input:not(:placeholder-shown) + label": {
            transform: "scale(0.6) translateY(-12px)",
          },
          label: {
            top: 0,
            left: 0,
            zIndex: 2,
            position: "absolute",
            mx: 3,
            px: 1,
            // my: 2,
            color: "brand.gray.500",
            transformOrigin: "left top",
          },
        },
      },
    },
  },
  Container: {
    baseStyle: {
      paddingY: 10,
      maxW: "container.xl",
    },
  },
  Input: {
    baseStyle: {
      field: {
        borderColor: "brand.gray.500",
      },
    },
  },
  List: {
    variants: {
      clean: {
        listStyle: "none",
        padding: 0,
      },
    },
  },
  Button: {
    baseStyle: {
      borderRadius: 0,
      fontWeight: 100,
    },
    variants: {
      base: {
        backgroundColor: "transparent",
        border: "1px solid black",
        color: "black",
        position: "relative",
        transition: "250ms color ease-out",
        zIndex: 1,
        _before: {
          content: "''",
          display: "block",
          bg: "black",
          position: "absolute",
          top: 0,
          width: "100%",
          height: "0%",
          transition: "250ms height ease-out",
          zIndex: -1,
        },
        _hover: {
          color: "white",
          _before: {
            height: "100%",
          },
        },
      },
      inverted: {
        backgroundColor: "black",
        border: "1px solid black",
        color: "white",
        position: "relative",
        transition: "250ms color ease-in",
        zIndex: 1,
        _before: {
          content: "''",
          display: "block",
          bg: "brand.gray.300",
          position: "absolute",
          top: 0,
          width: "100%",
          height: "0%",
          transition: "250ms height ease-in",
          zIndex: -1,
        },
        _hover: {
          color: "black",
          textDecoration: "none",
          _before: {
            height: "100%",
          },
        },
      },
      heroDark: {
        backgroundColor: "black",
        border: "1px solid black",
        color: "white",
        position: "relative",
        transition: "250ms color ease-in",
        zIndex: 1,
        _before: {
          content: "''",
          display: "block",
          bg: "brand.gray.300",
          position: "absolute",
          top: 0,
          width: "100%",
          height: "0%",
          transition: "250ms height ease-in",
          zIndex: -1,
        },
        _hover: {
          color: "black",
          _before: {
            height: "100%",
          },
        },
      },
      heroLight: {
        backgroundColor: "white",
        border: "none",
        color: "black",
        position: "relative",
        transition: "250ms color ease-in",
        zIndex: 1,
        _before: {
          content: "''",
          display: "block",
          bg: "black",
          position: "absolute",
          top: 0,
          width: "100%",
          height: "0%",
          transition: "250ms height ease-in",
          zIndex: -1,
        },
        _hover: {
          color: "white",
          _before: {
            height: "100%",
          },
        },
      },
      splideIcon: {
        _hover: {
          ringColor: "studio.gray.500",
          color: "studio.gray.500",
        },
        _disabled: {
          ring: "1px",
        },
        fontSize: "xl",
        ring: "1px",
        ringColor: "black",
        bgColor: "white",
        color: "black",
        rounded: "3xl",
        height: "32px",
        width: "32px",
      },
      icon: {
        _hover: {
          color: "black",
        },
        color: "brand.gray.700",
        ring: "1px",
        ringColor: "brand.gray.700",
      },
      link: {
        background: "transparent",
        fontSize: "inherit",
        fontWeight: "inherit",
        textTransform: "inherit",
        fontStyle: "inherit",
        color: "inherit",
        borderRadius: 0,
        minWidth: 0,
      },
    },
    defaultProps: {
      variant: "base",
    },
  },
};

const sizes = {
  container: { xl: "1370px" },
};

const theme = extendTheme(
  withDefaultColorScheme({
    colorScheme: "blackAlpha",
    components: ["Radio", "Checkbox", "Select"],
  }),
  {
    components,
    colors,
    fonts,
    transition,
    sizes,
  }
);

export default theme;
