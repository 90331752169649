import { FacetType } from "@components/Pages/FilterPage.model";
import { getCookie } from "@hooks/cookies";
import axios, { AxiosResponse } from "axios";

import {
  FileResource,
  FilterResultsModel,
  SearchVariantModel,
  VariantOptionModel,
} from "../modules/Search/Search.model";

export const FilterAndFacets = {
  Surface: {
    FacetMethodName: "SurfaceFacet",
    FacetName: "surface",
  },
  Color: { FacetMethodName: "ColorFacet", FacetName: "color" },

  ModelName: { FacetMethodName: "ModelName", FacetName: "modelName" },

  MaterialArmBack: {
    FacetMethodName: "MaterialArmBack",
    FacetName: "materialArmBack",
  },

  MaterialsDescription: {
    FacetMethodName: "MaterialsDropdown",
    FacetName: "materialsDescription",
  },

  MaterialProp: { FacetMethodName: "MaterialProp", FacetName: "materialProp" },

  ColorHexCodes: {
    FacetMethodName: "ColorHexCodesFilter",
    FacetName: "colorHexCodes",
  },

  ColorHexCode: { FacetMethodName: "ColorHexCode", FacetName: "colorHexCode" },

  DesignerName: { FacetMethodName: "DesignerName", FacetName: "designerName" },

  FabricName: { FacetMethodName: "FabricNameFacet", FacetName: "fabricName" },

  Material: { FacetMethodName: "Material", FacetName: "material" },

  HeightText: { FacetMethodName: "GetHeightTextEx", FacetName: "heightText" },

  Campaigns: { FacetMethodName: "CampaignsMergedEx", FacetName: "campaigns" },

  Category: { FacetMethodName: "Category", FacetName: "category" },

  ProductionDays: {
    FacetMethodName: "GetProductionDaysInheritedEx",
    FacetName: "productionDays",
  },

  PurchaseNotEnabled: {
    FacetMethodName: "PurchaseNotEnabled",
    FacetName: "purchaseNotEnabled",
  },
};

export const HelloRetailFilterName = {
  ExtraDataMaterial: "extraData.material",
  ExtraDataColor: "extraData.color",
  ExtraDataColorHexCode: "extraData.colorHexCode",
  ExtraDataSurface: "extraData.surface",
  Hierarchies: "hierarchies",
  Brand: "brand",
};

// #region search
interface HelloRetailSearchResponseType extends AxiosResponse {
  start: number;
  product_results: number;
  product_start: number;
  results: number;
  result: HelloRetailProduct[];
  category_start: number;
  category_results: number;
  categories: any[];
  filters: HelloRetailSearchFilters;
  filter_titles: HelloRetailSearchFilterTitles;
}
interface HelloRetailSearchFilters {
  brand: HelloRetailSearchTextFilter[];
  hierarchies: HelloRetailSearchHierarchyiFilter[];
  "extraData.systemCategory": HelloRetailSearchTextFilter[];
  "extraData.designerName": HelloRetailSearchTextFilter[];
  "extraData.materialsDescription": HelloRetailSearchTextFilter[];
  "extraData.fabricName": HelloRetailSearchTextFilter[];
  "extraData.modelName": HelloRetailSearchTextFilter[];
  "extraData.surface": HelloRetailSearchTextFilter[];
  "extraData.productionDays": HelloRetailSearchTextFilter[];
  "extraData.color": HelloRetailSearchTextFilter[];
  "extraData.colorHexCode": HelloRetailSearchTextFilter[];
  "extraData.material": HelloRetailSearchTextFilter[];
  "extraData.materialProp": HelloRetailSearchTextFilter[];
  "extraData.purchaseNotEnabled": HelloRetailSearchTextFilter[];
  "extraData.condition": HelloRetailSearchTextFilter[];
  price: HelloRetailSearchRangeFilter;
}
interface HelloRetailSearchTextFilter {
  title: string;
  count: number;
  query: string;
  selected: boolean;
}
interface HelloRetailSearchHierarchyiFilter
  extends HelloRetailSearchTextFilter {
  level: number;
}

interface HelloRetailSearchRangeFilter {
  min: number;
  max: number;
}

interface HelloRetailSearchFilterTitles {
  brand: string;
  hierarchies: string;
  "extraData.systemCategory": string;
  "extraData.designerName": string;
  "extraData.materialsDescription": string;
  "extraData.fabricName": string;
  "extraData.modelName": string;
  "extraData.surface": string;
  "extraData.productionDays": string;
  "extraData.color": string;
  "extraData.colorHexCode": string;
  "extraData.material": string;
  "extraData.materialProp": string;
  "extraData.purchaseNotEnabled": string;
  "extraData.condition": string;
  price: string;
}

// #endregion

// #region pages
interface HelloRetailPagesRequestBody {
  id?: number;
  url: string;
  format?: string;
  fields?: string[];
  params?: HelloRetailPagesRequestBodyParams;
  trackingUserId: string;
  firstLoad: boolean;
  products: HelloRetailPagesRequestBodyProducts;
}

interface HelloRetailPagesRequestBodyParams {
  filters: HelloRetailPagesRequestBodyParamsFilters;
}

interface HelloRetailPagesRequestBodyParamsFilters {
  hierarchies?: string[];
  brand?: string;
}

interface HelloRetailPagesRequestBodyProducts {
  start: number;
  count: number;
  filters?: string[];
  fields?: string[];
  sorting?: string[];
}

interface HelloRetailPagesResponseProducts {
  start: number;
  count: number;
  total: number;
  html?: string;
  javascript?: string;
  style?: string;
  result: HelloRetailProduct[];
  filters: HelloRetailPagesResponseFilter[];
  sorting: HelloRetailPagedsResponseSorting[];
  errors: any[];
  success: boolean;
}

interface HelloRetailPagesResponseFilter {
  name: string;
  settings: HelloRetailPagesResponseFilterSettings;
  values: HelloRetailPagesResponseFilterValue[];
  min?: number;
  max?: number;
  selectedMin?: number;
  selectedMax?: number;
}

interface HelloRetailPagesResponseFilterSettings {
  name: string;
  type: string;
  title: string;
  filteringText: string;
  negatedFilteringText: string;
}

interface HelloRetailPagesResponseFilterValue {
  title: string;
  count: number;
  query: string;
  selected: boolean;
}

interface HelloRetailPagedsResponseSorting {
  name: string;
  settings: HelloRetailPagesResponseSortingSettings;
  descending: HelloRetailPagedsResponseSortingOption;
  ascending: HelloRetailPagedsResponseSortingOption;
}

interface HelloRetailPagesResponseSortingSettings {
  name: string;
  title: string;
  ascendingText: string;
  descendingText: string;
}

interface HelloRetailPagedsResponseSortingOption {
  query: string;
  selected: boolean;
}

// #endregion

// #region recom
interface HelloRetailRecomRequestType {
  trackingUserId: string;
  requests: HelloRetailRecomRequest[];
}

interface HelloRetailRecomRequest {
  key: string;
  format: string;
  context: HelloRetailRecomRequestContext;
}

interface HelloRetailRecomRequestContext {
  hierarchies?: string[][];
  productNumbers?: string[];
  extraData?: HelloRetailRecomRequestContextExtraData;
}

interface HelloRetailRecomRequestContextExtraData {
  designerId?: string;
  designerName?: string;
  splash?: string;
}

interface HelloRetailRecomResponsesType extends AxiosResponse {
  responses: HelloRetailRecomResponseType[];
  success: boolean;
}

interface HelloRetailRecomResponseType {
  key: string;
  success: boolean;
  countAfterSource: string;
  products: HelloRetailProduct[];
}
// #endregion

// #region generic

export interface HelloRetailProduct {
  title: string;
  imgUrl: string;
  currency: string;
  description: string;
  keywords: string;
  brand: string;
  productNumber: string;
  // url: string;
  originalUrl: string;
  price: number;
  priceFormatted: string;
  oldPrice: number;
  oldPriceFormatted: string;
  isInSale: boolean;
  inStock: boolean;
  variantProductNumbers: string[];
  trackingCode: string;
  extraData: Record<string, string>;
}

export interface ImageMediaViewModel {
  url?: string;
}

export interface IFilterOption {
  // fill this based on your actual Model structure
}

export class FacetedVariantFilterModel {
  facets?: { [key: string]: IFilterOption[] };
  variants?: SearchVariantModel[];
  isModelOverview?: boolean;
  isSpecialView?: boolean;
}

export class ImageMediaViewModel {
  title?: string;
  alternativeText?: string;
  url?: string;
  width?: number;
  height?: number;
  focalPoint?: string;
}

export class ModelModel {
  campaigns?: string[];
  category: string;
  culture: string;
  description: string;
  descriptionHeader: string;
  designerId: string;
  designerName: string;
  designerSortKey: number;
  detailImage?: ImageMediaViewModel[];
  freeDelivery: boolean;
  friendlyModelName: string;
  galleryImage?: ImageMediaViewModel[];
  inRiverModelId: number;
  itemId: string;
  maintenanceGuideUrl: string;
  materialsDescription: string;
  modelName: string;
  productSheetUrl: string;
  sellWithoutStock: boolean;
  shortModelDescription: string;
  showOnWeb?: boolean;
  sketchImage?: ImageMediaViewModel[];
  sortKey: number;
  variantlessColorHexCodes?: string[];
  zipResources: FileResource[];

  // get galleryImageUrls() {
  //   return galleryImage?.map((gi) => gi.url);
  // }

  // get detailImageUrls() {
  //   return detailImage?.map((di) => di.url);
  // }

  // get sketchImageUrls() {
  //   return sketchImage?.map((si) => si.url);
  // }
}

export class VariantModel {
  backImage?: ImageMediaViewModel;
  bundledPrice: number;
  campaigns?: string[];
  carpetDimension: string;
  color: string;
  colorHexCode: string;
  colorHexCodes?: string[];
  configuration: string;
  cord?: string;
  culture: string;
  currency: string;
  dimension: string;
  drawers: string;
  extraImages?: ImageMediaViewModel[];
  fabricName: string;
  formattedLowestPrice?: string;
  formattedPrice?: string;
  frame: string;
  frontImage?: ImageMediaViewModel;
  galleryImage?: ImageMediaViewModel;
  hasWallmount: boolean;
  heightText: string;
  id: string;
  inRiverUpdated: string;
  inRiverVariantId: number;
  interior?: string;
  inventColorId?: string;
  isPreOrder?: boolean;
  isPrimary: boolean;
  isQuickship: boolean;
  lowestVariantPrice: number;
  material: string;
  materialArmBack: string;
  materialProp: string;
  model: ModelModel;
  notEnabledText?: string;
  oneWeekDelivery?: boolean;
  price: number;
  productNewLongName?: string;
  productionDays: number;
  pulloutText: string;
  purchaseNotEnabled?: boolean;
  shelfSuspension?: string;
  shippingInformation: string;
  showOnWeb?: boolean;
  sideImage?: ImageMediaViewModel;
  sku: string;
  sortKey: number;
  splash: string;
  stock: number;
  surface: string;
  upholstery: string;
  variantBundleIds: string[];
  variantCount: number;
  variantImageSize: string;
  variantModelIndex: number;
  variantName: string;
  variantOptions: VariantOptionModel[];
  variantPageUrl: string;
  trackingCode?: string;
  wood?: string;
}

// #endregion

export async function Global(
  searchTerm: string,
  HelloRetailApikey: string,
  HelloRetailBaseUrl: string
): Promise<Array<SearchVariantModel>> {
  searchTerm = searchTerm || "*";
  const variants: any[] = [];
  const address: string = `${HelloRetailBaseUrl}/api/v1/search/partnerSearch?key=${HelloRetailApikey}&format=json&q=${searchTerm}&product_count=100000`;

  const axiosResponse = await axios.get(address);
  if (axiosResponse.status !== 200) {
    throw new Error(axiosResponse.statusText);
  }
  const hlResponse: HelloRetailSearchResponseType = axiosResponse.data;

  for (let product of hlResponse.result) {
    variants.push(_createMinimalVariantModelForSearchResult(product));
  }

  return _convertToSearchVariantModels(variants);
}

// get frontImageUrl() {
//   return frontImage?.url;
// }
// get sideImageUrl() {
//   return sideImage?.url;
// }

// get backImageUrl() {
//   return backImage?.url;
// }
// get extraImageUrls() {
//   return extraImages?.map((x) => x.url);
// }

export function _convertToSearchVariantModels(
  variants: Array<VariantModel>
): Array<SearchVariantModel> {
  const result: Array<SearchVariantModel> = [];
  if (variants) {
    variants.forEach((v) => {
      const cleanVariant = { ...v };
      delete cleanVariant.model;

      const sv: SearchVariantModel = {
        ...cleanVariant,
        model: {
          ...v.model,
          detailImageUrls: v.model.detailImage?.map((i) => i.url),
          galleryImageUrls: v.model.galleryImage?.map((i) => i.url),
          sketchImageUrls: v.model.sketchImage?.map((i) => i.url),
          galleryImage: {
            Url: v.model.galleryImage[0].url,
            Title: v.model?.modelName,
            AlternativeText: v.model.friendlyModelName,
            FocalPoint: v.model.galleryImage[0].focalPoint,
          },
        },
        backImageUrl: v.backImage?.url,
        extraImageUrls: v.extraImages?.map((x) => x.url),
        frontImageUrl: v.frontImage?.url,
        sideImageUrl: v.sideImage?.url,
      };

      result.push(sv);
    });
  }
  return result;
}

export async function GetRecommendations(
  productIds: Array<string>,
  categories: Array<string>,
  designerId: string,
  designerName: string,
  helloRetailSplashFilter: string,
  helloRetailRecomEndpoint: string,
  helloRetailRecomKey: string
): Promise<HelloRetailRecomResponseType> {
  let trackingUserId = getCookie("hello_retail_id");
  if (!trackingUserId) {
    console.debug("GetRecommendations not called - missing trackingUserId");
    return null;
  }
  if (!helloRetailRecomKey) {
    console.debug(
      "GetRecommendations not called - missing helloRetailRecomKey"
    );
    return null;
  }
  let payloadRequest: HelloRetailRecomRequest = {
    key: helloRetailRecomKey,
    format: "json",
    context: {},
  };

  if (productIds && productIds.length > 0) {
    payloadRequest.context.productNumbers = productIds;
  }
  if (categories && categories.length > 0) {
    payloadRequest.context.hierarchies = [categories];
  }
  if (designerId || designerName || helloRetailSplashFilter) {
    payloadRequest.context.extraData = {};
  }
  if (designerId) {
    payloadRequest.context.extraData.designerId = designerId;
  }
  if (designerName) {
    payloadRequest.context.extraData.designerName = designerName;
  }
  if (helloRetailSplashFilter) {
    payloadRequest.context.extraData.splash = helloRetailSplashFilter;
  }

  let payload: HelloRetailRecomRequestType = {
    trackingUserId: trackingUserId,
    requests: [payloadRequest],
  };
  let response = await axios.post(helloRetailRecomEndpoint, payload);

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  const hlResponse: HelloRetailRecomResponsesType = response.data;
  return hlResponse.responses[0];
}

export async function GetInitialSearchRecommendations(
  productIds: Array<string>,
  categories: Array<string>,
  designerId: string,
  designerName: string,
  helloRetailSplashFilter: string,
  helloRetailRecomEndpoint: string,
  helloRetailRecomKey: string
): Promise<SearchVariantModel[]> {
  let trackingUserId = getCookie("hello_retail_id");

  if (!trackingUserId) {
    console.debug("GetInitialSearchRecommendations not called - missing trackingUserId");
    return null;
  }
  if (!helloRetailRecomKey) {
    console.debug(
      "GetInitialSearchRecommendations not called - missing helloRetailRecomKey"
    );
    return null;
  }

  const variants: any[] = [];
  let payloadRequest: HelloRetailRecomRequest = {
    key: helloRetailRecomKey,
    format: "json",
    context: {},
  };

  if (productIds && productIds.length > 0) {
    payloadRequest.context.productNumbers = productIds;
  }
  if (categories && categories.length > 0) {
    payloadRequest.context.hierarchies = [categories];
  }
  if (designerId || designerName || helloRetailSplashFilter) {
    payloadRequest.context.extraData = {};
  }
  if (designerId) {
    payloadRequest.context.extraData.designerId = designerId;
  }
  if (designerName) {
    payloadRequest.context.extraData.designerName = designerName;
  }
  if (helloRetailSplashFilter) {
    payloadRequest.context.extraData.splash = helloRetailSplashFilter;
  }

  let payload: HelloRetailRecomRequestType = {
    trackingUserId: trackingUserId,
    requests: [payloadRequest],
  };
  let response = await axios.post(helloRetailRecomEndpoint, payload);

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  const hlResponse: HelloRetailRecomResponsesType = response.data;
  hlResponse.responses[0];

  for (let product of hlResponse.responses[0].products) {
    variants.push(_createMinimalVariantModelForSearchResult(product));
  }

  return _convertToSearchVariantModels(variants);
}

export async function FacetedFilter(
  Category: string,
  Designer: string,
  filters: Array<string>,
  HelloRetailPagesApikey: string,
  HelloRetailBaseUrl: string
): Promise<FilterResultsModel> {
  let trackingUserId = getCookie("hello_retail_id");
  if (!HelloRetailPagesApikey) {
    console.debug("FacetedFilter not called - missing HelloRetailPagesApikey");
    return null;
  }

  let address: string = `${HelloRetailBaseUrl}/serve/pages/${HelloRetailPagesApikey}`;

  let variants: VariantModel[] = [];
  let facets: Record<string, FacetType[]> = {};
  let payloadRequest: HelloRetailPagesRequestBody = {
    url: window.location.href,
    format: "JSON",
    trackingUserId: trackingUserId,
    firstLoad: true,
    products: {
      start: 0,
      count: 1000,
      filters: filters.filter((f) => f),
    },
  };

  if (Designer) {
    payloadRequest.params = { filters: { brand: Designer } };
  } else if (Category) {
    let categories = Category.split(",");
    if (categories.length > 1) {
      payloadRequest.params = { filters: { hierarchies: categories } };
    } else {
      payloadRequest.params = { filters: { hierarchies: [Category] } };
    }
  }

  const response = await axios.post(address, payloadRequest);
  // console.log("FacetedFilter function response data:", response.data);
  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  const data: HelloRetailPagesResponseProducts = response.data.products;

  // Get variants for each productNumber in HelloRetail response
  for (let product of data.result) {
    variants.push(_createMinimalVariantModelForSearchResult(product));
  }

  let filterOnModel = filters.length > 0;

  let model: FilterResultsModel = {
    isModelOverview: !filterOnModel,
    isSpecialView: filterOnModel,
    facets: facets,
    variants: [],
  };

  if (data.filters) {
    data.filters.forEach((filter) => {
      const filterValueArray = filter.values;
      if (Array.isArray(filterValueArray)) {
        model.facets[filter.name] = [];
        filterValueArray.forEach((filterValue) => {
          const facet = {
            key: filterValue.query,
            count: filterValue.count,
            value: filterValue.query,
            text: filterValue.title,
            // selected: filter.selected,
          };

          model.facets[filter.name].push(facet);
        });
      } else {
        // todo handle HelloRetailRangeFilter
      }
    });
  }

  model.variants = _convertToSearchVariantModels(
    Designer ? _getVariantsGroupedByPrimaryOrderedByDesigner(variants, "") :
      filterOnModel ? variants :
        _getVariantsGroupedByPrimaryOrderedByDesigner(variants, "")
  );

  return model;
}

// #region Helper methods

function _extractValueAsString(
  extraData: Record<string, string>,
  key: string
): string {
  return extraData[key] || "";
}

function _extractValueAsInt(
  extraData: Record<string, string>,
  key: string
): number {
  const value: number = parseInt(extraData[key]);
  return isNaN(value) ? 0 : value;
}

function _extractValueAsBool(
  extraData: Record<string, string>,
  key: string
): boolean {
  return extraData[key] === "true" || extraData[key] === "True";
}

function _groupBy<T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
): T[][] {
  let map = new Map<K, T[]>();
  list.forEach((item) => {
    const key = getKey(item);
    const collection = map.get(key);
    if (collection) {
      collection.push(item);
    } else {
      map.set(key, [item]);
    }
  });
  return Array.from(map.values());
}

function _getVariantsGroupedByPrimaryOrderedByDesigner(
  variantModels: VariantModel[],
  q: string
): VariantModel[] {
  let results: VariantModel[] = [];
  let groupBy = _groupBy(variantModels, (x) => x.model.itemId);
  for (let group of groupBy) {
    let primary = group.sort((a, b) => a.sortKey - b.sortKey)[0];
    primary.variantCount = group.length;
    primary.formattedLowestPrice = group[0].formattedLowestPrice;
    results.push(primary);
  }
  // If we do not have a query sort the products
  if (!q) {
    return results.sort(
      (a, b) =>
        a.model.designerSortKey - b.model.designerSortKey ||
        a.model.designerId.localeCompare(b.model.designerId) ||
        a.model.sortKey - b.model.sortKey
    );
  }
  return results;
}

function _replaceFilterWithHelloRetailSyntax(
  filter: string,
  key: string,
  replacementKey: string,
  firstFilter: boolean = false
): string {
  let filterArr: string[] = filter.split(":");
  let filterValue: string | undefined = filterArr.find((x) => x != key);
  let filterName: string = (filterArr[0] || "").replace(key, replacementKey);

  // Hello Retail escaped special chars in category only. In facets the query-property is allready escaped
  filterValue = filterValue.replace(/\s/g, "\\ ");
  filterValue = filterValue.replace(/\&/g, "\\&");
  filterValue = filterValue.replace(/-/g, "\\-");

  filter = `${filterName}:${filterValue}`;

  if (firstFilter) {
    filter += "$";
  }

  return filter;
}

function _handleCategoryFilter(filter) {
  if (filter.includes(FilterAndFacets.Category.FacetName)) {
    filter = _replaceFilterWithHelloRetailSyntax(
      filter,
      FilterAndFacets.Category.FacetName,
      HelloRetailFilterName.Hierarchies,
      true
    );
  }
  return filter;
}

export function _createMinimalVariantModelForSearchResult(
  product: HelloRetailProduct
): VariantModel {
  let material = _extractValueAsString(product.extraData, "material");
  let surface = _extractValueAsString(product.extraData, "surface");
  let color = _extractValueAsString(product.extraData, "color");
  let frame = _extractValueAsString(product.extraData, "frame");
  let galleryImage = _extractValueAsString(product?.extraData, "galleryImage");
  let galleryImageFocalPoint = _extractValueAsString(
    product?.extraData,
    "galleryImageFocalPoint"
  );

  let materialArmBack = _extractValueAsString(
    product.extraData,
    "materialArmBack"
  );
  let fabricName = _extractValueAsString(product.extraData, "fabricName");
  let variantName = _extractValueAsString(product.extraData, "variantName");
  let isPreOrder = _extractValueAsBool(product.extraData, "isPreOrder");
  let sortKey = _extractValueAsInt(product.extraData, "variantSortKey");
  let modelSortKey = _extractValueAsInt(product.extraData, "modelSortKey");
  let designerSortKey = _extractValueAsInt(
    product.extraData,
    "designerSortKey"
  );
  let designerId = _extractValueAsString(product.extraData, "designerId");
  let friendlyModelName = _extractValueAsString(
    product.extraData,
    "friendlyModelName"
  );
  let modelName = _extractValueAsString(product.extraData, "modelName");
  let designerName = _extractValueAsString(product.extraData, "designerName");
  let sellableWithoutStock = _extractValueAsBool(
    product.extraData,
    "sellWithoutStock"
  );
  let formattedLowestPrice = _extractValueAsString(
    product.extraData,
    "formattedLowestPrice"
  );
  let stock = _extractValueAsInt(product.extraData, "stock");
  let itemId = _extractValueAsString(product.extraData, "itemId");
  let voyadoId = _extractValueAsString(product.extraData, "voyadoId");
  let variantImageSize = _extractValueAsString(
    product.extraData,
    "variantImageSize"
  );
  let purchaseNotEnabled = _extractValueAsBool(
    product.extraData,
    "purchaseNotEnabled"
  );
  let purchaseNotEnabledText = _extractValueAsString(
    product.extraData,
    "purchaseNotEnabledText"
  );
  let splash = _extractValueAsString(product.extraData, "splash");

  return {
    currency: product.currency,
    id: voyadoId,
    sku: product.productNumber,
    frontImage: { url: product.imgUrl },
    variantPageUrl: product.originalUrl,
    trackingCode: product.trackingCode,
    formattedLowestPrice: formattedLowestPrice,
    formattedPrice: product.priceFormatted,
    price: product.price,
    isPreOrder: isPreOrder,
    stock: stock,
    variantName: variantName,
    material: material,
    surface: surface,
    color: color,
    frame: frame,
    fabricName: fabricName,
    materialArmBack: materialArmBack,
    variantImageSize: variantImageSize,
    bundledPrice: 0,
    carpetDimension: "",
    colorHexCode: "",
    configuration: "",
    culture: "",
    dimension: "",
    drawers: "",
    hasWallmount: false,
    heightText: "",
    inRiverUpdated: "",
    inRiverVariantId: 0,
    isPrimary: true,
    isQuickship: false,
    lowestVariantPrice: 0,
    materialProp: "",
    productionDays: 0,
    purchaseNotEnabled: purchaseNotEnabled,
    notEnabledText: purchaseNotEnabledText,
    pulloutText: "",
    shippingInformation: "",
    sortKey: sortKey,
    splash: splash,
    upholstery: "",
    variantBundleIds: [],
    variantCount: 0,
    variantModelIndex: 0,
    variantOptions: [],
    model: {
      category: "",
      culture: "",
      description: "",
      descriptionHeader: "",
      designerId: designerId,
      designerName: designerName,
      designerSortKey: designerSortKey,
      freeDelivery: false,
      friendlyModelName: friendlyModelName,
      inRiverModelId: 0,
      itemId: itemId,
      maintenanceGuideUrl: "",
      materialsDescription: "",
      modelName: modelName,
      productSheetUrl: "",
      sellWithoutStock: sellableWithoutStock,
      shortModelDescription: "",
      sortKey: modelSortKey,
      zipResources: [],
      galleryImage: [{ url: galleryImage, focalPoint: galleryImageFocalPoint }],
    },
  };
}
// #endregion
