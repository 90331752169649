import { 
    Link as Anchor,
    Box,
    Button,
  } from "@chakra-ui/react";
import {ChevronRightIcon, ChevronDownIcon } from "@chakra-ui/icons";
import NextLink from "next/link";
import { useState } from "react";
import { useNavigation } from "./useNavigation";
import { MainMenuItemType } from "./Navigation.model";

function SubMenuItem({
    Link: { Url, Title, Target },
    Children,
    setShowSubMenuItemPreUnfolded,
  }: MainMenuItemType & { setShowSubMenuItemPreUnfolded?: (value: boolean) => void }){
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [showSubmenu, setShowSubmenu] = useState(false);
    const setLinks = useNavigation()[1];
    const handleOnClick = (index) => {
      if (index === currentIndex) {
          setCurrentIndex(null);
          setShowSubMenuItemPreUnfolded(false);
          setShowSubmenu(!showSubmenu || currentIndex === index);
      } else {
          setShowSubmenu(!showSubmenu || currentIndex !== index);
          setCurrentIndex(index);
          setShowSubMenuItemPreUnfolded(false);
      }
  };

    const handleOnClickLeafMenuItem = () => {
        setLinks((state) => ({ ...state, Links: Children }));
    }

    const handleOnClickTopMenuItem = () => {
      setShowSubmenu(!showSubmenu);
      setShowSubMenuItemPreUnfolded(false);
      setCurrentIndex(null);
    }

    return (
        <Box>
            {Children.length > 0 ? ( 
            <Box>
            <Box> 
                <Button width={"100%"} justifyContent="space-between" py={3} _hover={{ textDecoration: "none" }} onClick={() => handleOnClickTopMenuItem()} variant="link">
                   {Title} {showSubmenu ? <ChevronDownIcon boxSize={6} color={"#7a7a7a"}/> :<ChevronRightIcon boxSize={6} color={"#7a7a7a"}/>}
                </Button>
            </Box>            
                {showSubmenu ? (
                <Box>
                    {Children.map((child, index) => (
                      <>
                      {child.Children.length > 0 ? (
                      <Box>
                        <Button key={index} py={3} pl={5} width={"100%"} justifyContent="space-between" _hover={{ textDecoration: "none" }} onClick={() => handleOnClick(index)} variant="link">
                          {child.Link.Title} {child.Children.length <= 0 ? <></> : showSubmenu && currentIndex === index ? <ChevronDownIcon boxSize={6} color={"#7a7a7a"}/> : <ChevronRightIcon boxSize={6} color={"#7a7a7a"}/>}
                        </Button>
                        <Box pl={10}>
                            {index === currentIndex && Children[currentIndex] && Children[currentIndex].Children.map((child, index) => (
                            <SubMenuItem key={index} {...child} setShowSubMenuItemPreUnfolded={setShowSubMenuItemPreUnfolded} />
                            ))}
                        </Box>
                      </Box>
                      ) : (
                        <Button key={index} py={3} pl={5} width={"100%"} justifyContent="space-between" _hover={{ textDecoration: "none" }} variant="link">
                          <NextLink href={child.Link.Url || ""} passHref>
                            <Anchor display={"block"} _hover={{ textDecoration: "none" }} target={child.Link.Target} >{child.Link.Title}</Anchor>
                          </NextLink>
                      </Button>
                      )}
                      </>
                ))}
            </Box>
            ): <></>}
            </Box>
            ):( 
              <Button onClick={handleOnClickLeafMenuItem} variant="link" _hover={{ textDecoration: "none" }}>
                <NextLink href={Url || ""} passHref>
                  <Anchor display={"block"} _hover={{ textDecoration: "none" }} py={3} target={Target}>{Title}</Anchor>
                </NextLink>
              </Button>
            )}
        </Box>
    );
  };

  export default SubMenuItem;