import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, IconButton } from "@chakra-ui/react";
import { GenericCarouselType } from "@components/GenericCarousel/GenericCarousel.model";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import React, { MutableRefObject } from "react";

const GenericCarouselComponent = React.forwardRef(
  (
    {
      controls = false,
      perPage = 1,
      perMove = perPage,
      interval = 4000,
      ariaLabel = "Carousel",
      autoWidth = false,
      type = "slide",
      pagination = false,
      position = { top: -10, right: 0 },
      children,
      ...rest
    }: GenericCarouselType,
    ref: MutableRefObject<Splide>
  ) => {
    const hideControls = children?.length <= perPage;
    return (
      <Splide
        {...rest}
        ref={ref}
        aria-label={ariaLabel}
        options={{
          gap: "1rem",
          pagination,
          arrows: controls && !hideControls,
          autoplay: !controls,
          interval,
          rewind: false,
          type,
          perPage,
          autoWidth,
          perMove,
          easing: "ease-out",
          ...rest.options,
        }}
        hasTrack={false}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          position="absolute"
          sx={position}
          width="120px"
          display="flex"
          justifyContent="space-between"
          className="splide__arrows"
        >
          <IconButton
            aria-label="previous item"
            size="sm"
            className="splide__arrow splide__arrow--prev"
            icon={<ChevronRightIcon />}
            variant="splideIcon"
          />
          <IconButton
            aria-label="next item"
            size="sm"
            className="splide__arrow splide__arrow--next"
            icon={<ChevronRightIcon />}
            variant="splideIcon"
          />
        </Box>
        <SplideTrack>
          {React.Children.map(children, (child) => (
            <SplideSlide style={{ marginBottom: "2rem" }}>{child}</SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
    );
  }
);

export default GenericCarouselComponent;
