import { Button, Icon, ListItem, UnorderedList } from "@chakra-ui/react";
import BasketNav from "@components/BasketNav.component";
import FavoritesNav from "@components/FavoritesNav.component";
import { useAppData } from "@hooks/useAppData";
import { useNavigation } from "@modules/Navigation/useNavigation";
import { BiSearch } from "react-icons/bi";

function FunctionsMenuComponent() {
  const [navData, setNavData] = useNavigation();
  const iconHeight = "18px";
  const [
    {
      marketData: { PurchasesEnabled },
    },
  ] = useAppData();
  const handleSearch = () => {
    setNavData({ ...navData, SearchOpen: !navData.SearchOpen });
  };
  return (
    <UnorderedList
      listStyleType="none"
      padding={0}
      display="flex"
      fontSize="lg"
    >
      <ListItem paddingX={1} height={iconHeight}>
        <Button
          aria-label="Search"
          display="inline-block"
          onClick={handleSearch}
          variant="link"
        >
          <Icon as={BiSearch} />
        </Button>
      </ListItem>
      <ListItem paddingX={1} height={iconHeight}>
        <FavoritesNav />
      </ListItem>
      {PurchasesEnabled ? (
        <ListItem paddingX={1} height={iconHeight}>
          <BasketNav />
        </ListItem>
      ) : (
        <></>
      )}
    </UnorderedList>
  );
}

export default FunctionsMenuComponent;
