import { 
  Link as Anchor,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Icon,
  Input,
  InputGroup,
  InputLeftElement
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import MotionBox from "@components/MotionBox.component";
import CountryLanguagePickerComponent from "@modules/Navigation/CountryLanguagePicker/CountryLanguagePicker.component";
import MobileMenuFooter from "./MobileMenuFooter.component";
import SubMenu from "./SubMenu.component";
import { useNavigation } from "./useNavigation";
import { useTranslations } from "next-intl";
import { MainMenuItemProps, MenuLinkType, MobileMenuType } from "./Navigation.model";
import { BiSearch } from "react-icons/bi";

function MainMenuItem({
  Link: { Url, Title, Target },
  Children,
  setPrevNavigationTitle,
}: MainMenuItemProps) {
  const setLinks = useNavigation()[1];

  const handleOnClick = (title: string) => {
    setPrevNavigationTitle(title);
    setLinks((state) => ({ ...state, Links: Children }));
  };

  return (
    <ListItem fontSize="sm" my={6} >
      {Children.length > 0 ? (
        <Box >
          <Button onClick={() => handleOnClick(Title)} variant="link" width={"100%"} justifyContent="space-between" _hover={{ textDecoration: "none" }}>
            {Title} <ChevronRightIcon boxSize={6} color={"#7a7a7a"}/>
          </Button>
        </Box>
      ) : (
        <NextLink href={Url} passHref>
          <Anchor target={Target} _hover={{ textDecoration: "none" }}>{Title}</Anchor>
        </NextLink>
      )}
    </ListItem>
  );
}

function MetaMenuItem({ Url, Title, Target }: MenuLinkType) {
  return (
    <ListItem fontSize="sm" my={4}>
      <NextLink href={Url} passHref>
        <Anchor target={Target} _hover={{ textDecoration: "none" }}>{Title}</Anchor>
      </NextLink>
    </ListItem>
  );
}

type MobileMenuTypeProps = MobileMenuType & {
  handleLanguageSelector: (open: boolean) => void;
  languageSelectorActive: boolean;
  selectedCountry: string;
  setSelectedCountry: (country: string) => void;
  selectedLanguage: string;
  setSelectedLanguage: (language: string) => void;
};

function MobileMenuComponent({
  MainMenu,
  MetaMenu,
  handleLanguageSelector,
  languageSelectorActive,
  selectedCountry,
  setSelectedCountry,
  selectedLanguage,
  setSelectedLanguage,
}: MobileMenuTypeProps) {
  const [{ Open: showMenu, Links: childMenu }, toggleMenu] = useNavigation();
  const [menuClosing, setMenuClosing] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenuClosing, setSubMenuClosing] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const router = useRouter();
  const transitionTime = 250;
  const [navData, setNavData] = useNavigation();
  const dict = useTranslations("Search");
  const [currentUnfoldedIndex, setCurrentUnfoldedIndex] = useState(0);
  const [prevNavigationTitle, setPrevNavigationTitle] = useState('');

  const handleMenu = (isOpen) => {
    if (!isOpen && !menuClosing) {
      setMenuOpen(true);
      toggleMenu((state) => ({ ...state, Open: true }));
    }

    if (isOpen && !menuClosing) {
      handleLanguageSelector(false);
      setMenuClosing(true);
      setTimeout(() => {
        setMenuOpen(false);
        toggleMenu((state) => ({ ...state, Open: false}));
        setMenuClosing(false);
      }, transitionTime);
    }
  };

  const handleSubmenu = (isOpen) => {
    if (!isOpen && !menuClosing) {
      setSubMenuOpen(true);
    }

    if (isOpen && !menuClosing) {
      setSubMenuClosing(true);
      setTimeout(() => {
        setSubMenuOpen(false);
        toggleMenu((state) => ({ ...state, Links: [] }));
        setSubMenuClosing(false);
      }, transitionTime);
    }
  };

  const handleLangSelector = () => {
    handleLanguageSelector(!languageSelectorActive);
  };

  const handleSearch = () => {
    setNavData({ ...navData, SearchOpen: !navData.SearchOpen });
  };

  useEffect(() => {
    setSubMenuOpen(childMenu.length > 0);
    if (!showMenu) handleMenu(true);
    if (showMenu) handleMenu(false);
  }, [showMenu, childMenu, router.asPath]); // eslint-disable-line

  return (
    <Box>
      {menuOpen && (
        <MotionBox
          position="fixed"
          top={50}
          width="100%"
          left={0}
          zIndex={5}
          backgroundColor="white"
          height="calc(100vh - 50px)"
          overflowY="auto"
          initial={{ opacity: menuOpen ? 0 : 1 }}
          animate={{ opacity: menuClosing ? [1, 0] : [0, 1] }}
          transition={{ duration: transitionTime / 1000 }}
        >
          <MotionBox
            py={3}
            px={5}
            initial={{ translateY: menuOpen ? "0%" : "-100%" }}
            animate={{
              translateY: menuClosing ? ["0%", "-100%"] : ["-100%", "0%"],
            }}
            transition={{ duration: transitionTime / 1000 }}
          >
            <Button aria-label="Search" display="inline-block" onClick={handleSearch} variant="link" width="100%" _hover={{ textDecoration: "none" }}>  
          <InputGroup>
            <InputLeftElement className="InputLeft" boxSize={"8"} pointerEvents="none" children={<Icon as={BiSearch}/>} />
            <Input placeholder={dict("InputPlaceholderSearchDesktop")} size='sm' width="100%" borderRadius={0} borderColor={"#D4D3D3"}/>
            </InputGroup>
          </Button>
          </MotionBox>
          <Divider borderColor={"#C0BEBE"}/>
          <MotionBox
            initial={{ translateY: menuOpen ? "0%" : "-100%" }}
            animate={{
              translateY: menuClosing ? ["0%", "-100%"] : ["-100%", "0%"],
            }}
            transition={{ duration: transitionTime / 1000 }}
          >
            <List variant="clean" px={5}>
              {MainMenu.Links.map((MenuItem) => (
                <MainMenuItem Link={MenuItem.Link} Children={MenuItem.Children} setPrevNavigationTitle={setPrevNavigationTitle} key={MenuItem.Link.Title} />
              ))}
            </List>
            <Divider my={8} />
            <List variant="clean" px={5}>
              {MetaMenu.Links.map((Link) => (
                <MetaMenuItem {...Link} key={Link.Title} />
              ))}
            </List>
            <Divider my={8} />
            <List variant="clean" px={5} pb={5}>
              <ListItem fontSize="sm" my={3}>
                <Button onClick={handleLangSelector} variant="link" _hover={{ textDecoration: "none" }}>
                  {MetaMenu?.CountryPicker?.Title}
                </Button>
              </ListItem>
            </List>
            <MotionBox
              bg="white"
              color="black"
              fontWeight="semibold"
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              initial={{ opacity: 0 }}
              animate={{ opacity: languageSelectorActive ? 1 : 0 }}
              transition={{ opacity: { duration: transitionTime / 1000 } }}
              padding={5}
              style={{ pointerEvents: languageSelectorActive ? "all" : "none" }}
            >
              <CountryLanguagePickerComponent
                {...MetaMenu.CountryPicker}
                handleLanguageSelector={handleLanguageSelector}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                showAnnouncement={false}
              />
            </MotionBox>
            {subMenuOpen && (
              <MotionBox
                position="absolute"
                top={0}
                left={0}
                backgroundColor="white"
                width="100%"
                height="100%" 
                initial={{ opacity: subMenuOpen ? 0 : 1 }}
                animate={{
                  opacity: subMenuClosing ? [1, 0] : [0, 1],
                }}
                transition={{ duration: transitionTime / 1000 }}
              >
                <Button variant="unstyled" 
                  display="flex" 
                  alignItems="center" 
                  py={3} px={4} mt={"-6"}
                  height={12}
                  justifyContent="start"
                  width="100%" 
                  backgroundColor="#F8F8F8" 
                  _hover={{ textDecoration: "none" }}
                  onClick={() => {handleSubmenu(subMenuOpen)}}
                >
                  <Icon as={ChevronLeftIcon} boxSize={6} mr={3} color={"#7a7a7a"}/> {prevNavigationTitle}
                </Button>
                <Divider borderColor={"#C0BEBE"}/>
                <MotionBox
                 px={5}
                 pt={5}
                 pb={20}
                >
                <List variant="clean">
                  {childMenu && childMenu.map((child, index) => (
                  <ListItem fontSize="sm">
                    <SubMenu key={index} {...child} subMenuIndex={index} currentUnfoldedIndex={currentUnfoldedIndex} setCurrentUnfoldedIndex={setCurrentUnfoldedIndex}/>
                  </ListItem>
                  ))}
                </List>
                </MotionBox>
              </MotionBox>)}
              <MobileMenuFooter {...MainMenu} />
          </MotionBox>
        </MotionBox>
      )}
    </Box>
  );
}

export default MobileMenuComponent;
