import { Icon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { useAppData } from "@hooks/useAppData";
import useFavorites from "@hooks/useFavorites";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

function FavoritesButton({
  id,
  type = "product",
  showText = false,
}: {
  id: string;
  type?: "case" | "product";
  showText?: boolean;
}) {
  const translate = useTranslations("Favourites");
  const [{ favorites }] = useAppData();
  const [active, setActive] = useState(false);

  const { loading, upsertFavorite } = useFavorites();

  const typeId = type === "case" ? "1" : "2";

  useEffect(() => {
    if (!loading && favorites && Array.isArray(favorites)) {
      const favIds = favorites.map((fav) => fav.favoriteId);
      setActive(favIds.includes(id.toString()));
    } else if (!loading && favorites) setActive(false);
  }, [favorites]);

  const handleFavorites = () => {
    upsertFavorite(id, typeId, active);
  };

  return (
    <Button
      onClick={handleFavorites}
      variant="link"
      isLoading={loading}
      height={5}
    >
      <Icon
        as={active ? AiFillHeart : AiOutlineHeart}
        marginRight={1}
        height="100%"
        width={5}
      />
      {showText && translate("ButtonAddToFavourites")}
    </Button>
  );
}

export default FavoritesButton;
