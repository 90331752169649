import { ReactComponent as Logo } from "@assets/svg/logo.svg";
import {
  Link as Anchor,
  Box,
  Container,
  GridItem,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import NextLink from "next/link";

function MobileMenuFooter(MainMenu) {
  return (
    <Box bgColor="brand.gray.300">
      <Container
        maxW="container.xl"
        paddingTop={10}
        paddingBottom={24}
        paddingX={{ base: 5, lg: 20 }}
      >
        <SimpleGrid columns={{ base: 4, md: 6, lg: 12 }} spacing={8}>
           <GridItem
                colSpan={{ base: 2, lg: 3 }}
                key={MainMenu?.FooterLinks?.Link?.Title}
              >
                <Heading variant="heading3" mb={4}>
                  {MainMenu?.FooterLinks?.Link?.Title}
                </Heading>
                {MainMenu?.FooterLinks?.Children?.map((child, index) => (
                  <NextLink
                    href={child?.Link?.Url}
                    target={child?.Link?.Target}
                    key={`${MainMenu?.Link?.Title}.${child?.Link?.Title}`}
                    passHref
                  >
                    <Anchor _hover={{ textDecoration: "none" }}>
                      <Text lineHeight={7}>{child?.Link?.Title}</Text>
                    </Anchor>
                  </NextLink>
                ))}
              </GridItem>          
        </SimpleGrid>
      </Container>
    </Box>
  );
}
export default MobileMenuFooter;
