import {
  Link as Anchor,
  Box,
  Heading,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { Element } from "domhandler/lib/node";
import parse, { HTMLReactParserOptions, domToReact } from "html-react-parser";
import NextLink from "next/link";

function RichTextComponent({ text }: { text: string }) {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      // Because of typing issues...
      if (domNode instanceof Element && domNode.name && domNode.attribs) {
        const { attribs, children, name } = domNode;
        switch (name) {
          case "h1":
            return (
              <Heading fontSize="24px" variant="heading1" as={name}>
                {domToReact(children)}
              </Heading>
            );
          case "h2":
            return (
              <Heading variant="heading2" as={name}>
                {domToReact(children)}
              </Heading>
            );
          case "h3":
            return (
              <Heading variant="heading3" as={name}>
                {domToReact(children)}
              </Heading>
            );
          case "h4":
          case "h5":
          case "h6":
            return (
              <Heading variant="heading4" as={name}>
                {domToReact(children)}
              </Heading>
            );
          case "br":
            return;
          case "ul":
          case "ol":
            return (
              <List variant="clean" as={name}>
                {domToReact(children, options)}
              </List>
            );
          case "li":
            return <ListItem>{domToReact(children, options)}</ListItem>;
          case "div":
            return <Box>{domToReact(children, options)}</Box>;
          case "a":
            return (
              <NextLink href={attribs.href}>
                <Anchor variant="richtext">
                  {domToReact(children, options)}
                </Anchor>
              </NextLink>
            );
          default:
            return (
              /* eslint-disable */
              <Text variant="richtext" fontSize="md" as={name as any}>
                {domToReact(children)}
              </Text>
            );
        }
      } else {
        return;
      }
    },
  };
  if (typeof text !== "string") return null;
  return <Box>{parse(text, options)}</Box>;
}

export default RichTextComponent;
