import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Link as Anchor, Box, Button, ButtonProps } from "@chakra-ui/react";
import { useAppData } from "@hooks/useAppData";
import useBasket from "@hooks/useBasket";
import { useTranslations } from "next-intl";
import NextLink from "next/link";

interface AddBasketButtonProps {
  qty?: number;
  disabledLink?: string;
  Sku?: string;
  Stock?: number;
  SellWithoutStock?: boolean;
  PurchaseNotEnabled?: boolean;
  NotEnabledText?: string;
  ProductionDays?: number;
  IsPreOrder?: boolean;
  hideBuyButton?: boolean;
  price?: number;
}

export function sellableWithoutStockFunc(Stock: number, SellWithoutStock: boolean) {
  if (!!Stock) {
    return true;
  }
  return !!SellWithoutStock;
}

function AddBasketButton({
  Sku,
  Stock,
  SellWithoutStock,
  PurchaseNotEnabled,
  NotEnabledText,
  ProductionDays,
  IsPreOrder,
  disabledLink,
  variant = "inverted",
  qty = 1,
  children,
  hideBuyButton = false,
  price,
  ...rest
}: ButtonProps & AddBasketButtonProps) {
  const dict = useTranslations("ProductPage.Hero");
  const storeLocatorDict = useTranslations("StoreLocator");
  const { upsertBasketLine, getQuantity, loading } = useBasket();

  const [
    {
      marketData: { PurchasesEnabled },
    },
  ] = useAppData();

  const quantity = getQuantity(Sku) + qty || qty;

  const handleOnClick = () => {
    upsertBasketLine(Sku, quantity);
  };

  const sellableWithoutStock = sellableWithoutStockFunc(Stock, SellWithoutStock);

  const canPurchaseFunc = (Sku?: string, ProductionDays?: number) => {
    if (!Sku || !ProductionDays) {
      return false;
    }
    const isProduced = ProductionDays > 0;
    return isProduced && PurchasesEnabled;
  };
  const canPurchase = canPurchaseFunc(Sku, ProductionDays);

  const enabled = canPurchase && sellableWithoutStock && !PurchaseNotEnabled;

  return (
    <>
      {PurchasesEnabled && !hideBuyButton ? (
        <>
          <Button
            onClick={handleOnClick}
            isLoading={loading}
            variant={variant}
            isDisabled={!enabled}
            {...rest}
            mt={4}
          >
            {addToButtonText(enabled, IsPreOrder, dict, children, NotEnabledText, sellableWithoutStock)}
          </Button>
          {!enabled && disabledLink ? (
            <Box mx="auto" mt={2}>
              <NextLink href={disabledLink} passHref>
                <Anchor>
                  {storeLocatorDict("Header")} <ArrowForwardIcon />
                </Anchor>
              </NextLink>
            </Box>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default AddBasketButton;

function addToButtonText(enabled: boolean, IsPreOrder: boolean, dict, children, NotEnabledText: string, sellableWithOutStock: boolean) {
  if (enabled) {
    if (IsPreOrder) {
      return dict("ButtonPreorder");
    } else {
      return children || dict("ButtonAddToCart");
    }
  } else if (!enabled && !sellableWithOutStock) {
    return dict("TextSoldOut");
  } else {
    return NotEnabledText || dict("StockNotAvailable");
  }
}