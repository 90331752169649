import getConfig from "@hooks/getConfig";
import { useAppData } from "@hooks/useAppData";
import { Basket, BasketAddress } from "@modules/Basket/Basket.model";
import axios from "axios";
import fetch from "cross-fetch";
import { useLocale } from "next-intl";
import { useEffect, useState } from "react";
import { useLocalstorageState } from "rooks";

import { getFirstDayOfWeek } from "./getTime";
import useTracking from "./useTracking";

function useBasket() {
  const { apiEndpoint, edgeEndpoint } = getConfig();
  const locale = useLocale();
  const [loading, setLoading] = useState(false);
  const [isInvalidBasket, setIsInvalidBasket] = useState(false);
  const [data, setData] = useState<Basket | null>(null);
  const [error, setError] = useState(null);
  const { trackAddRemoveBasket, trackCompletedPurchase } = useTracking();
  const [basketId, setBasketId] = useLocalstorageState("CHS-Basket-ID", null);
  const [trackId, setTrackId] = useLocalstorageState("CHS-TrackId", null); // set at payment, read & delete at receipt to fire purchase tracking event.
  const [appData, setAppData] = useAppData();

  const [
    {
      marketData: { MarketId },
    },
  ] = useAppData();

  if (!basketId && data) {
    setBasketId(data.BasketId);
  }

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setAppData({ ...appData, basket: data });
    }
  }, [data]);

  const getQuantity = (variantId: string) => {
    const [{ basket }] = useAppData();
    if (!basket) return;
    const match = basket?.Lines
      ? basket?.Lines.find((line) => line.VariantId === variantId)
      : null;
    return match?.Quantity || null;
  };

  const upsertBasketLine = async (variantId: string, quantity: number) => {
    try {
      setLoading(true);

      const body = JSON.stringify({
        locale,
        basketId,
        marketId: MarketId,
        variantId,
        quantity,
      });

      const res = await fetch([edgeEndpoint, "basket", "upsert"].join("/"), {
        method: "PUT",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await res.json();
      setData(json);
      setLoading(false);
      if (json) {
        trackAddRemoveBasket(variantId, json.Lines);
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  interface UpsertAddressInputModel {
    CustomerRef?: string;
    PurchOrderFormNum?: string;
    VatNum?: string;
    DeliveryAddress?: BasketAddress;
    SeperateInvoiceAddress?: BasketAddress;
    BasketId?: string;
    MarketId?: string;
  }

  const upsertAddress = async (props: UpsertAddressInputModel) => {
    const body = JSON.stringify({
      ...props,
      BasketId: basketId,
      MarketId: MarketId,
      locale,
    });
    return putRequestTo("upsertAddress", body);
  };

  interface upsertShippingModel {
    ShippingIds?: string;
    DeliveryDate?: string;
  }

  const upsertShipping = async ({
    ShippingIds: shipIds,
    DeliveryDate: dd,
  }: upsertShippingModel) => {
    if (!shipIds || !dd) return;
    const ShippingIds = [shipIds];
    const DeliveryDate = getFirstDayOfWeek(dd);

    const body = JSON.stringify({
      ShippingIds,
      DeliveryDate,
      BasketId: basketId,
      MarketId: MarketId,
      locale,
    });
    return putRequestTo("upsertShipping", body);
  };
  interface upsertPaymentModel {
    PaymentId: string;
    endpointId?: string;
  }

  const upsertPayment = async ({
    PaymentId,
    endpointId = "Altapay",
  }: upsertPaymentModel) => {
    if (!trackId) {
      setTrackId(basketId);
    }
    const body = JSON.stringify({
      PaymentId,
      endpointId,
      BasketId: basketId,
      MarketId: MarketId,
      locale,
    });
    const res = await putRequestTo("upsertPayment", body);

    if (!!res) {
      const externalPayment = await getPaymentForm({ endpointId });
      const { TargerUrl, PaymentUrl, Parameters } = externalPayment;
      if (PaymentUrl) {
        generatePaymentForm(PaymentUrl, Parameters);
      }
      return TargerUrl;
    } else return false;
  };
  //redeploy
  function generatePaymentForm(
    action: string,
    fields: { [key: string]: string }
  ): HTMLFormElement {
    const form = document.createElement("form");

    form.setAttribute("method", "POST");
    form.setAttribute("action", action);

    Object.entries(fields).forEach(([name, value], idx) => {
      return form.appendChild(generateHiddenInputField({ name, value }));
    });
    document.body.appendChild(form);
    form.submit();
    return form;
  }

  function generateHiddenInputField({
    name,
    value,
  }: {
    name: string;
    value: string;
  }): HTMLInputElement {
    const input = document.createElement("input");

    input.setAttribute("type", "hidden");
    input.setAttribute("name", name);
    input.setAttribute("value", value);

    return input;
  }

  interface upsertVoucherModel {
    VoucherCode: string;
  }

  const upsertVoucher = async ({ VoucherCode }: upsertVoucherModel) => {
    const body = JSON.stringify({
      VoucherCode,
      BasketId: basketId,
      MarketId: MarketId,
      locale,
    });
    return putRequestTo("upsertVoucher", body);
  };

  const putRequestTo = async (subPath: string, body: BodyInit) => {
    try {
      setLoading(true);

      const res = await fetch([edgeEndpoint, "basket", subPath].join("/"), {
        method: "PUT",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.ok) {
        const json = await res.json();
        setData(json);
        setLoading(false);
        return res.ok;
      }
      const json = await res.json();
      setError(json?.Message || res.statusText);
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return false;
    }
  };

  interface getPaymentFormModel {
    endpointId: string;
  }
  const getPaymentForm = async ({
    endpointId = "Altapay",
  }: getPaymentFormModel) => {
    try {
      setLoading(true);
      const body = JSON.stringify({
        locale,
        basketId,
        marketId: MarketId,
        endpointId,
      });
      const res = await fetch(
        [edgeEndpoint, "basket", "getPaymentForm"].join("/"),
        {
          method: "POST",
          body,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("getPaymentForm response body", res);
      const json = await res.json();
      console.log("getPaymentForm json", json);

      return json;
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const getDeliveryWeeks = async (week: number) => {
    try {
      setLoading(true);

      const body = JSON.stringify({
        locale,
        week,
      });
      const res = await fetch(
        [edgeEndpoint, "basket", "getDeliveryWeeks"].join("/"),
        {
          method: "POST",
          body,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await res.json();
      return json;
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  interface fetchReceiptDataModel {
    orderId: string;
  }
  const fetchReceiptData = async ({ orderId }: fetchReceiptDataModel) => {
    try {
      if (!orderId) return;
      setLoading(true);

      // new code
      const url = [apiEndpoint, locale, MarketId, "receipt"].join("/");

      const params = new URLSearchParams({
        orderId,
      });

      const fetchString = [url, params.toString()].join("?");
      const res = await axios.get(fetchString);

      const json = await res.data;
      if (json && trackId) {
        trackCompletedPurchase(json);
        setTrackId(null);
      }
      return json;
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const getDeliveryMethods = async () => {
    try {
      const body = JSON.stringify({
        locale,
        basketId,
        marketId: MarketId,
      });

      const res = await fetch(
        [edgeEndpoint, "basket", "getDeliveryMethods"].join("/"),
        {
          method: "POST",
          body,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await res.json();

      return json;
    } catch (error) {
      setError(error.message);
    }
  };

  const getPaymentSuppliers = async () => {
    try {
      const body = JSON.stringify({
        locale,
        marketId: MarketId,
      });

      const res = await fetch(
        [edgeEndpoint, "basket", "getPaymentSuppliers"].join("/"),
        {
          method: "POST",
          body,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await res.json();

      return json;
    } catch (error) {
      setError(error.message);
    }
  };

  const getBasket = async () => {
    try {
      setLoading(true);

      const body = JSON.stringify({
        locale,
        basketId,
        marketId: MarketId,
      });
      const res = await fetch([edgeEndpoint, "basket"].join("/"), {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await res.json();
      setData(json);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const checkSoldOutItems = (basket) => {
    if (basket != null) {
      const soldOutItemExists = basket.Lines.some(line => line.IsSoldOut);
      if (soldOutItemExists) {
        setIsInvalidBasket(true);
      } else {
        setIsInvalidBasket(false);
      }
    }
  };

  return {
    getBasket,
    upsertBasketLine,
    upsertAddress,
    upsertShipping,
    upsertPayment,
    upsertVoucher,
    getDeliveryWeeks,
    getDeliveryMethods,
    getPaymentSuppliers,
    getQuantity,
    getPaymentForm,
    fetchReceiptData,
    data,
    loading,
    error,
    setError,
    checkSoldOutItems,
    isInvalidBasket
  };
}

export default useBasket;
