import { Link as Anchor, Box, Button, Text, useMediaQuery } from "@chakra-ui/react";
import Image, { CloudinaryNextImage } from "@components/Image";
import { ImageAspectRatiosProps } from "@components/Image/Image.model";
import { ProductImageType } from "@components/ProductList/ProductList.model";
import useTracking from "@hooks/useTracking";
import NextLink from "next/link";
import React, { useState } from "react";
import { CalculateFocalPoint } from "@services/focalPoint.service";

function getRatio(format): ImageAspectRatiosProps | number {
  const height = 360 * (9 / 16);
  if (format === "wide") return height;
  else if (format === "square") return "1:1";
  else return "4:5";

  // Old function code. Replaced with above code because we want all items to be same height now
  // if (format === "wide") return "16:9";
  // else if (format === "square") return "1:1";
  // else return "4:5";
}

function ProductImage(
  props: ProductImageType & {
    priority?: boolean;
    sizes?: string;
    height?: string;
  }
) {
  const [isHovered, setHovered] = useState(false);
  const { image, splash, url, format, priority, galleryImage, trackingCode } = props;
  const aspectRatio = getRatio(format);
  const { trackProductClick } = useTracking();

  const imageUrl = image && image.Url;
  const hoveredImage = galleryImage && galleryImage.Url;

  var position = CalculateFocalPoint(galleryImage?.FocalPoint);


  const [isLargeScreen] = useMediaQuery(
    "(min-width: 768px) and (max-width: 1370px)"
  );

  if (!image?.Url || !url) return;

  return (
    <Box
      className="ProductImage"
      display={"grid"}
      alignItems={"center"}
      p={format === "square" ? 2 : 6}
      height={props.height || "auto"}
      maxHeight={isLargeScreen ? "35vw" : "500px"}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      position="relative"
    >
      <Text
        top="1"
        left="2"
        fontSize="xs"
        fontWeight="thin"
        position="absolute"
      >
        {splash}
      </Text>

      <NextLink href={url} passHref>
        <a onClick={() => trackProductClick(trackingCode)}>
          <Anchor display="block">
            <Box width="100%" overflow="hidden">
              <Image
                src={imageUrl}
                alt={image.AlternativeText}
                aspectRatio={aspectRatio.toString() as ImageAspectRatiosProps}
                sizes={props.sizes || "50vw 25vw"}
                size="medium"
                priority={priority}
                style={{
                  opacity: hoveredImage ? (isHovered ? 0 : 1) : 1,
                  transition:
                    "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
                  transform:
                    !hoveredImage && isHovered ? "scale(1)" : "scale(0.9)",
                }}
              />
            </Box>
            {hoveredImage && (
              <CloudinaryNextImage
                src={hoveredImage}
                sizes="(max-width: 768px) 66vw,
                  500px"
                layout="fill"
                objectFit="cover"
                objectPosition={position}
                style={{
                  opacity: isHovered ? 1 : 0,
                  transition: "opacity 0.5s ease-in-out",
                }}
              />
            )}
          </Anchor>
        </a>
      </NextLink>
    </Box>
  );
}

export default ProductImage;
