import "@assets/fonts/fonts.css";
import { ChakraProvider } from "@chakra-ui/provider";
import { SkipNavContent, SkipNavLink } from "@chakra-ui/skip-nav";
import { LoadingSplash } from "@components/LoadingSplash.component";
import { AppProvider } from "@context/AppContext";
import { getCookie } from "@hooks/cookies";
import getConfig from "@hooks/getConfig";
import DefaultLayout from "@layouts/Default.layout";
import { PageBuilderType } from "@modules/PageBuilder/PageBuilder.model";
import theme from "@theme/default.theme";
import { useClientSideState } from "@zustand/clientSideState";
import { MotionConfig } from "framer-motion";
import { NextIntlProvider } from "next-intl";
import type { AppProps as NextAppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useState } from "react";
import { IPInfo } from "./api/ipapi";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AppProps<P = any> = {
  pageProps: PageBuilderType;
} & Omit<NextAppProps<P>, "pageProps">;

function App({ Component, pageProps }: AppProps) {
  const { gaMeasurementId, primaryDomain } = getConfig();
  const { setPriceListData, setCurrentProductIds, setCurrentCategoryIds, setCurrentDesignerId } = useClientSideState();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [noIndex, setNoIndex] = useState(false);

  // console.log("App", {pageProps:Object.keys(pageProps)});
  const ready = "pageData" in pageProps && "messages" in pageProps && "marketData" in pageProps && "priceListData" in pageProps && true;

  const router = useRouter();
  const {
    query: { slug }
  } = router;
  useEffect(() => {
    setPriceListData(pageProps?.priceListData);
  }, [pageProps?.priceListData]);

  useEffect(() => {
     if(!window.location.href.includes(primaryDomain)){
        setNoIndex(true);
      }else{
        setNoIndex(false);
      }

    if(!(/bot|crawler|spider|crawling/i.test(navigator.userAgent) || process.env.NODE_ENV === "development")) {
      let temp = router.asPath.split("/");
      if (temp[0] === "") {
        temp.shift();
      }

      let currentLocale = router.locale;
      let currentMarket = temp[0];
      
      if(!currentMarket) {
        console.debug("missing market ('en' bug in nextjs router)")
      }

      const getChsLocaleFromIpApi = async () => {
        const apiKey = "fca4dfe3219a3c0ec721f0ca00e6e84d";
        const ipapiresponse = await fetch("https://ipapi.co/json/", {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const ipapidata : IPInfo = await ipapiresponse.json();
  
        let chsLocale = "en/en";
        const ipapiCountryCode = ipapidata.country_code.toLowerCase()
        switch(ipapiCountryCode) {
          case "dk":
            chsLocale = "da-dk/dadk";
            break;
          case "se":
            chsLocale = "sv-se/svse";
            break;
          case "de":
            chsLocale = "de-de/dede";
            break;
          case "pl":
            chsLocale = "de-de/pl";
            break;
          case "jp":
            chsLocale = "ja-jp/jajp";
            break;
          case "nl":
            chsLocale ="nl-nl/nlnl";
            break;
          default:
            if(validLocales["en"].includes(ipapiCountryCode)) {
              chsLocale = "en/" + ipapiCountryCode;
            } else {
              chsLocale = "en/en";
            }
            break;
        }
        redirectBasedOnChsLocale(chsLocale)
      }

      const redirectBasedOnChsLocale = (chsLocale : string) => {
        if(process.env.NEXT_PUBLIC_VERCEL_ENV !== 'preview'){
          if(chsLocale){
            const splitChsLocale = chsLocale.split("/")
            const newLocale = splitChsLocale[0]
            const newMarket = splitChsLocale[1]
            const firstMatch = pageData.HrefLangUrls.find(element => element.LanguageCode == newLocale)
            if(firstMatch && newLocale !== currentLocale && newMarket !== currentMarket) {
              // we are on the wrong locale/market - redirect
              document.cookie = `CHS_LOCALE=${chsLocale}; max-age=31536000; path=/`;
              document.cookie = `NEXT_LOCALE=${newLocale.toLowerCase()}; max-age=31536000; path=/`;
              let newUrl = firstMatch.Url
                .replace("en/en", chsLocale)
                .replace("da-dk/dadk", chsLocale)
                .replace("sv-se/svse", chsLocale)
                .replace("de-de/dede", chsLocale)
                .replace("nl-nl/nlnl", chsLocale)
                .replace("ja-jp/jajp", chsLocale);
              window.location.href = newUrl
            }
        }
      }
    }

      // check if cookie choice exists and is valid
      const CHS_LOCALE = getCookie("CHS_LOCALE")
      const validLocales = {
        "da-dk": ["dadk"],
        "de-de": ["dede", "pl",],
        "en": ["en", "ro", "pt", "sk", "lt", "fi", "hu", "ee", "bg", "hr", "sl", "lv", "ie", "cz", "at", "be", "nlnl", "fr", "int", "it", "jp", "es", "de", "se", "pl", "lu"],
        "ja-jp": ["jajp"],
        "nl-nl": ["nlnl"],
        "sv-se": ["svse"],
      };
      const splitChsLocale = CHS_LOCALE && CHS_LOCALE.split('/');
      if (splitChsLocale
        && splitChsLocale.length == 2
        && validLocales.hasOwnProperty(splitChsLocale[0].toLowerCase())
        && validLocales[splitChsLocale[0].toLowerCase()].includes(splitChsLocale[1].toLocaleLowerCase())) {

        // cookie based redirect
        redirectBasedOnChsLocale(CHS_LOCALE)
      } else {
        // ip based redirect
        getChsLocaleFromIpApi().catch(console.error)
      }
    }
  }, [])

  useEffect(() => {
    if(pageData) {
      let sku = pageData["Variants"] &&pageData["Variants"][0] && pageData["Variants"][0]["Sku"] && [pageData["Variants"][0]["Sku"]];
      setCurrentProductIds(sku)
      setCurrentDesignerId(pageData["DesignerId"])
      setCurrentCategoryIds(pageData["Category"] && [pageData["Category"]])
      let hrq = window.hrq || [];
      hrq.push([
          "reload"
      ]);
    }
  }, [slug])

  if (!ready) {
    // console.log("App LoadingSplash", {ready});
    return <LoadingSplash />;
  }

  const pageData = pageProps.pageData;
  const cookieLanguage = pageProps?.marketData?.Language?.split("-")[0].toUpperCase();
  return (
    <NextIntlProvider
      messages={pageProps.messages}
      onError={(err) => {
        if (err.code === "MISSING_MESSAGE") {
          console.warn("Missing message", err.message, Object.keys(pageProps.messages));
          return;
        }
        throw err;
      }}
    >
      <ChakraProvider theme={theme}>
        <MotionConfig reducedMotion="user">
          <AppProvider marketData={pageProps.marketData} staticData={pageProps.staticData} favorites={[]} basket={null}>
            <Head>
              <title>Carl Hansen & Søn</title>
              <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
              <meta name="generator" content="Episerver Commerce" />
              {noIndex ? <meta name="robots" content="noindex" /> : <></>}
              <meta name="google-site-verification" content="DXwIYNCBYhsLNdsqafIcXW0FkkcGJ_uQrw9eAs41eJ8" />
              <title>{pageData.MetaDataTitle}</title>
              <meta name="description" content={pageData.MetaDataDescription} />
              {pageData.HrefLangUrls.map((x) => (
                <link rel="alternate" key={x.LanguageCode} hrefLang={x.LanguageCode} href={x.Url} />
              ))}
              <link rel="canonical" href={pageData.CanonicalUrl} />
            </Head>
            <Script id="helloretail" async src="https://helloretailcdn.com/helloretail.js" type="text/javascript"></Script>
            <Script
              id="helloretail-init"
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `window.hrq = window.hrq || []; hrq.push(['init', { trackPageView: false }]);`,
              }}
            />
            <Script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `window.hrq = window.hrq || [];
                  hrq.push(function(sdk) {
                    if (!document.cookie.includes("hr_initial_load")) {
                      document.cookie = "hr_initial_load=true";
                      sdk.setTrackingOptOut(true);
                    }
                });`,
              }}
            />
            <Script id="CookieConsent" src="https://policy.app.cookieinformation.com/uc.js" data-culture={cookieLanguage || "EN"} type="text/javascript" />
            {gaMeasurementId ? (
              <Script
                id="google-analytics"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gaMeasurementId}')`,
                }}
              />
            ) : (
              <></>
            )}

            <SkipNavLink />
            <DefaultLayout
              marketData={pageProps.marketData}
              Kind={pageProps?.pageData?.Kind}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            >
              <SkipNavContent />
              <Component {...pageProps} />
            </DefaultLayout>
            {gaMeasurementId ? (
              <></>
            ) : (
              <Script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `window.hrq = window.hrq || [];
                  hrq.push(["setTrackingOptOut", false]);`,
                }}
              />
            )}
            {/* <Analytics/>
            <SpeedInsights route={router.pathname} /> */}
          </AppProvider>
        </MotionConfig>
      </ChakraProvider>
    </NextIntlProvider>
  );
}

export default App;
