import { Box, Button } from "@chakra-ui/react";
import React from "react";

import { CountryPickerType } from "./Navigation.model";

type CountryPickerComponentProps = CountryPickerType & {
  handleLanguageSelector: (open: boolean) => void;
  languageSelectorActive: boolean;
};

function CountryPickerComponent({
  Title,
  handleLanguageSelector,
  languageSelectorActive,
}: CountryPickerComponentProps) {
  const isScrolledToTop = () => {
    return (
      (document.documentElement.scrollTop || document.body.scrollTop) === 0
    );
  };

  const handleOnClick = () => {
    if (languageSelectorActive && isScrolledToTop()) {
      handleLanguageSelector(false);
    } else if (languageSelectorActive) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      handleLanguageSelector(true);
    }
  };

  return (
    <Box>
      <Button
        className="language-button"
        fontSize="inherit"
        variant="link"
        color="black"
        onClick={handleOnClick}
        fontWeight="inherit"
      >
        {Title}
      </Button>
    </Box>
  );
}

export default CountryPickerComponent;
