import { Box, Flex, Link, Text } from "@chakra-ui/react";
import ProductImage from "@components/ProductList/ProductImage.component";
import getPriceFormat from "@hooks/getPriceFormat";
import NextLink from "next/link";

import { BasketLineModel } from "../BasketLine/BasketLine.model";

function MiniBasketLine({ currency = "", ...props }: BasketLineModel) {
  return (
    <Flex alignItems="center" pr={8}>
      <Box
        bg="brand.gray.300"
        width="90px"
        height="90px"
        boxShadow="lg"
        flexShrink="0"
        flexGrow="0"
      >
        <ProductImage
          image={{
            AlternativeText: props.Name,
            Title: props.Name,
            Url: props.FrontImageUrl,
          }}
          url={props.PageUrl}
          format="square"
        />
      </Box>
      <Box my={2} pl={4} width="100%">
        <NextLink href={props.PageUrl || "#"} passHref>
          <Link
            fontSize="sm"
            fontWeight={700}
            noOfLines={1}
            autoCapitalize="true"
          >
            {props.Name} | {props.DesignerName}
          </Link>
        </NextLink>
        <Text fontSize="xs" fontWeight={300} pb={4} height="2.5rem">
          {props.Description}
        </Text>
        <Flex justifyContent="space-between" alignItems="center">
          <Text
            fontSize="sm"
            fontWeight="semibold"
            pb={4}
            color="brand.gray.500"
          >
            {props.Quantity} x {getPriceFormat(props.Price, currency)}
          </Text>

          <Text fontSize="sm" fontWeight="semibold" pb={4}>
            {getPriceFormat(props.TotalPrice, currency)}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
}

export default MiniBasketLine;
