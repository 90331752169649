import { Alert, AlertDescription } from "@chakra-ui/react";

export interface AnnouncementProps {
  Show: boolean;
  Announcement: string;
}

export const AnnouncementBar = ({
  Show = false,
  Announcement = "",
}: AnnouncementProps) => {
  if (!Show) return;
  return (
    <Alert
      position="fixed"
      top={0}
      width="100vw"
      height="40px"
      zIndex={30}
      status="error"
      textAlign="center"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      <AlertDescription>{Announcement}</AlertDescription>
    </Alert>
  );
};
