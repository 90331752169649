import {
  Box,
  Flex,
  Icon,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useAppData } from "@hooks/useAppData";
import useBasket from "@hooks/useBasket";
import MiniBasket from "@modules/Basket/MiniBasket";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { AiFillShopping, AiOutlineShopping } from "react-icons/ai";

function BasketNav() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [{ basket, marketData }] = useAppData();
  const NumberOfProducts = basket?.NumberOfProducts;
  const { getBasket } = useBasket();
  const [showBasket, setShowBasket] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [initialNumProducts, setInitialNumProducts] =
    useState<Number>(NumberOfProducts);
  const { Urls } = marketData;
  const router = useRouter();
  const localizedBasketName = Urls.CheckoutBasket.split("/")[3] || "basket";
  const isBasketPage = router.asPath.includes(localizedBasketName);

  useEffect(() => {
    getBasket();
  }, []);

  useEffect(() => {
    if (initialNumProducts === undefined) {
      setInitialNumProducts(NumberOfProducts);
    }
    if (
      Number.isInteger(initialNumProducts) &&
      NumberOfProducts &&
      NumberOfProducts > 0 &&
      NumberOfProducts !== initialNumProducts
    ) {
      setTimeout(() => {
        setShowBasket(false);
      }, 5000);
      setTimeout(() => {
        setUpdating(false);
      }, 1000);
      setUpdating(true);
      setShowBasket(true);
    }
  }, [NumberOfProducts]);

  return (
    <Box
      onMouseEnter={() => {
        setShowBasket(true);
      }}
      onMouseLeave={() => {
        setShowBasket(false);
      }}
    >
      <Flex alignItems="center">
        {NumberOfProducts && NumberOfProducts > 0 ? (
          <Text as="span" fontSize="xs" marginRight={0.5}>
            {NumberOfProducts}
          </Text>
        ) : (
          <></>
        )}
        <NextLink href={Urls.CheckoutBasket || "/basket"} passHref>
          <Link type="solid">
            <Icon as={updating ? AiFillShopping : AiOutlineShopping} />
          </Link>
        </NextLink>
      </Flex>
      {!isMobile && !isBasketPage && showBasket && <MiniBasket />}
    </Box>
  );
}

export default BasketNav;
