export function CalculateFocalPoint(focalPoint: string) {
    if (focalPoint) {
        const position =
            focalPoint
                .split(",")
                .map((n) => parseFloat(n) * 100 + "%")
                .join(" ")
        return position;
    }
    return "50% 50%"
}

