import {
  PriceListEntryType
} from "@context/AppContext/App.model";
import getConfig from "@hooks/getConfig";
import { useAppData } from "@hooks/useAppData";
import { SearchVariantModel } from "@modules/Search/Search.model";
import { Global } from "@services/helloRetail.service";
import { useClientSideState } from "@zustand/clientSideState";
import { useState } from "react";
import useTracking from "./useTracking";

interface SearchParams {
  searchTerm: string;
}

function useSearch(locale: string) {
  const { edgeEndpoint } = getConfig();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);
  const [data, setData] = useState<SearchVariantModel[] | null>(null);
  const { trackSearch } = useTracking();
  const [{ marketData: { MarketId, HelloRetailApikey, HelloRetailBaseUrl } }] = useAppData();
  const { priceListData } = useClientSideState();


  const search = async (params: SearchParams): Promise<void> => {
    try {
      setLoading(true);
      let resultData = await Global(params?.searchTerm, HelloRetailApikey, HelloRetailBaseUrl)

      if (resultData && priceListData) {
        const priceReplacedVariants = []
        // substitute marketId and remove absolute part of URL from search result
        resultData.forEach((res: SearchVariantModel) => {
          if (res.variantPageUrl) {
            const urlParts = res.variantPageUrl.split("/")
            if (urlParts.length > 4) {
              const newUrl = ["", urlParts[3], MarketId.toLowerCase(), ...urlParts.slice(5)]
              res.variantPageUrl = newUrl.join("/")
            }
          }
          // substitute prices
          if (res.sku && priceListData.prices && Object.keys(priceListData.prices).length > 0) {
            const priceRecord: PriceListEntryType = priceListData.prices[res.sku]
            if (priceRecord) {
              res.lowestVariantPrice = priceRecord.price
              res.formattedLowestPrice = priceRecord.formattedPrice
              res.price = priceRecord.price
              res.formattedPrice = priceRecord.formattedPrice
              res.currency = priceRecord.currency
              res.listedPrice = priceRecord.listedPrice
              res.formattedListedPrice = priceRecord.formattedListedPrice
              priceReplacedVariants.push(res) // only take variants that has prices
            }
          } else {
            // No prices exist for market
            res.lowestVariantPrice = null
            res.formattedLowestPrice = ""
            res.price = null
            res.formattedPrice = null
            res.currency = null
            priceReplacedVariants.push(res)
          }
        })
        resultData = priceReplacedVariants;
      }

      if (resultData && Array.isArray(resultData)) {
        trackSearch(params.searchTerm, resultData.length);
      }
      setData(resultData);
      setLoading(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const clear = () => {
    setData(null);
  };

  const searchBySku = async (skuCodes: string[]) => {
    try {
      const res = await fetch([edgeEndpoint, "search", "sku"].join("/"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ locale, marketId: MarketId, skuCodes }),
      });

      const json = await res.json();
      setData(json);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return { search, searchBySku, data, error, loading, clear };
}

export default useSearch;
