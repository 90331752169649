import { ReactComponent as LogoMobile } from "@assets/svg/logo-mobile.svg";
import { CloseIcon, HamburgerIcon, Icon } from "@chakra-ui/icons";
import { Link as Anchor, Button, HStack } from "@chakra-ui/react";
import BasketNav from "@components/BasketNav.component";
import FavoritesNav from "@components/FavoritesNav.component";
import MotionBox from "@components/MotionBox.component";
import { useAppData } from "@hooks/useAppData";
import { useAnimation } from "framer-motion";
import NextLink from "next/link";
import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";

import MobileMenu from "./MobileMenu.component";
import { useNavigation } from "./useNavigation";

function MobileNavigationComponent(props) {
  const { Urls } = props;
  //// const scrollDirection = useScrollDirection();
  const [minimizeNav, setMinimizeNav] = useState(false);
  const [{ Open: menuOpen }, setMenu] = useNavigation();
  const logoControls = useAnimation();
  const [navData, setNavData] = useNavigation();
  const [
    {
      marketData: { PurchasesEnabled },
    },
  ] = useAppData();
  const handleOnClick = () => {
    setMenu((state) => ({ ...state, Open: !menuOpen }));
  };

  const handleSearch = () => {
    setNavData({ ...navData, SearchOpen: !navData.SearchOpen });
  };

  useEffect(() => {
    logoControls.start(() => ({
      opacity: [0, 1],
    }));
  }, [menuOpen]); // eslint-disable-line

  return (
    <>
      <MotionBox
        position="fixed"
        top={props.showAnnouncement ? "40px" : 0}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        height={50}
        backgroundColor="white"
        zIndex={20}
        p={4}
        inital={{ translateY: minimizeNav ? "0%" : "-100%" }}
        animate={{
          translateY: minimizeNav ? ["0", "-100%"] : ["-100%", "0%"],
        }}
        transition={{ duration: 0.25 }}
      >
        <NextLink href={Urls?.Home} passHref>
          <Anchor>
            <MotionBox
              animate={logoControls}
              transition={{ duration: 0.25 }}
              display="flex"
              justifyContent="center"
            >
              {menuOpen ? (
                <Icon as={LogoMobile} width="130px" fontSize="24px" />
              ) : (
                <Icon as={LogoMobile} width="130px" />
              )}
            </MotionBox>
          </Anchor>
        </NextLink>

        <HStack spacing={4} alignItems="center">
          <Button
            aria-label="Search"
            display="inline-block"
            onClick={handleSearch}
            variant="link"
            height={"16px"}
          >
            <Icon as={BiSearch} />
          </Button>
          {menuOpen && (
            <>
              <FavoritesNav />
            </>
          )}
          {PurchasesEnabled ? <BasketNav /> : <></>}
          <Button
            aria-label={menuOpen ? "Close Menu" : "Open Menu"}
            display="inline-block"
            onClick={handleOnClick}
            variant="link"
            pb={1}
          >
            <Icon as={menuOpen ? CloseIcon : HamburgerIcon} />
          </Button>
        </HStack>
      </MotionBox>
      <MobileMenu {...props} />
    </>
  );
}

export default MobileNavigationComponent;
