import { Ref, useEffect, useRef } from "react";

export const useOnOutsideClick = (handleOutsideClick) => {
  const innerBorderRef: Ref<any> = useRef();

  const onClick = (event) => {
    if (
      innerBorderRef.current &&
      !innerBorderRef.current.contains(event.target) &&
      !event.originalTarget?.classList.contains("menu-toggle")
    ) {
      handleOutsideClick();
    }
  };

  useMountEffect(() => {
    document.addEventListener("click", onClick, true);
    return () => {
      document.removeEventListener("click", onClick, true);
    };
  });

  return { innerBorderRef };
};

export const useOnOutsideLanguageSelectorClick = (handleOutsideClick) => {
  const languageSelectorRef: Ref<any> = useRef();

  const onClick = (event) => {
    if (
      languageSelectorRef.current &&
      !languageSelectorRef.current.contains(event.target) &&
      !event.target?.classList.contains("language-button")
    ) {
      handleOutsideClick();
    }
  };

  useMountEffect(() => {
    document.addEventListener("click", onClick, true);
    return () => {
      document.removeEventListener("click", onClick, true);
    };
  });

  return { languageSelectorRef };
};

const useMountEffect = (callback: () => void) => useEffect(callback);
