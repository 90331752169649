import { Link as Anchor, Box, Heading, Text } from "@chakra-ui/react";

import { FooterType } from "./Footer.studio.model";

function contactInfo({
  Title,
  Name,
  Department,
  Phone,
  Mail,
  AddressLine1,
  AddressLine2,
  AddressLine3,
  Zipcode,
  City,
}: FooterType["ContactInfo"]) {
  return (
    <>
      <Heading variant="heading3" mb={4}>
        {Title}
      </Heading>
      <Box>
        <Text size="sm" lineHeight={7} fontWeight="light">
          {Name}
        </Text>
        <Anchor href={`tel:${Phone}`}>
          <Text size="sm" lineHeight={7} fontWeight="light">
            {Phone}
          </Text>
        </Anchor>
        <Anchor href={`mailto:${Mail}`}>
          <Text size="sm" lineHeight={7} fontWeight="light">
            {Mail}
          </Text>
        </Anchor>
      </Box>
      <Box paddingTop={2}>
        <Text size="sm" lineHeight={7} fontWeight="light">
          {AddressLine1}
        </Text>
        <Text size="sm" lineHeight={7} fontWeight="light">
          {AddressLine2}
        </Text>
        <Text size="sm" lineHeight={7} fontWeight="light">
          {AddressLine3}
        </Text>
        <Text size="sm" lineHeight={7} fontWeight="light">
          {Zipcode} {City}
        </Text>
      </Box>
    </>
  );
}
export default contactInfo;
