import { NextConfig } from "next";
import getNextConfig from "next/config";

function getConfig(isPublic = true): NextConfig {
  const runtime = isPublic ? "publicRuntimeConfig" : "serverRuntimeConfig";
  const nextConfig = getNextConfig();
  return nextConfig[runtime];
}

export default getConfig;
