import { Icon } from "@chakra-ui/icons";
import { Flex, Link, Text } from "@chakra-ui/react";
import { useAppData } from "@hooks/useAppData";
import useFavorites from "@hooks/useFavorites";
import NextLink from "next/link";
import { useEffect, useState } from "react";
import { AiFillHeart } from "react-icons/ai";
import { BiHeart } from "react-icons/bi";

function FavoritesNav() {
  const { getFavorites } = useFavorites();
  const [{ favorites, marketData }] = useAppData();
  const { Urls } = marketData;
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    getFavorites();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setUpdating(false);
    }, 1000);
    setUpdating(true);
  }, [favorites?.length]);

  return (
    <Flex alignItems="center">
      <Text as="span" fontSize="xs" marginRight={0.5}>
        {favorites?.length}
      </Text>
      <NextLink href={Urls.FavoritPageUrl} passHref>
        <Link>
          <Icon as={updating ? AiFillHeart : BiHeart} />
        </Link>
      </NextLink>
    </Flex>
  );
}

export default FavoritesNav;
