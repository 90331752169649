import { ReactComponent as Logo } from "@assets/svg/logo.svg";
import { ReactComponent as StudioLogo } from "@assets/svg/studioLogoPrimary.svg";
import { Icon } from "@chakra-ui/icons";
import { Flex, keyframes, usePrefersReducedMotion } from "@chakra-ui/react";

interface Props {
  variant?: "default" | "studio";
}

export function LoadingSplash({ variant = "default" }: Props) {
  const prefersReducedMotion = usePrefersReducedMotion();
  const fade = keyframes`
  from {opacity: 0.5;}
  to {opacity: 1}
`;
  const fadeAnimation = prefersReducedMotion
    ? undefined
    : `${fade} 1.2s ease-in-out infinite alternate`;
  return (
    <Flex justifyContent="center" alignItems="center" height="100vh">
      <Icon
        as={variant === "default" ? Logo : StudioLogo}
        animation={fadeAnimation}
        width={variant === "default" ? "400px" : "300px"}
        height="auto"
      />
    </Flex>
  );
}
