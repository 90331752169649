import { 
    Link as Anchor,
    Box,
    Button,
  } from "@chakra-ui/react";
import {ChevronRightIcon, ChevronDownIcon } from "@chakra-ui/icons";
import NextLink from "next/link";
import { useEffect, useState } from "react";
import { useNavigation } from "./useNavigation";
import { MainMenuItemType } from "./Navigation.model";
import SubMenuItem from "./SubMenuItem.component";

function SubMenu({
    Link: { Url, Title, Target },
    Children,
    subMenuIndex,
    currentUnfoldedIndex,
    setCurrentUnfoldedIndex
  }: MainMenuItemType & {subMenuIndex:number} & {currentUnfoldedIndex:number} & {setCurrentUnfoldedIndex: (value: number) => void }){

    const setLinks = useNavigation()[1];
    const [showSubMenuItemPreUnfolded, setShowSubMenuItemPreUnfolded] = useState(true);
    const [showSubMenuPreUnfolded, setShowSubMenuPreUnfolded] = useState(true);

    const handleOnClickLeafMenuItem = () => {
        setLinks((state) => ({ ...state, Links: Children }));
    }

    const handleOnClickTopMenuItem = (index) => {
        setShowSubMenuPreUnfolded(!showSubMenuPreUnfolded)
        if(currentUnfoldedIndex === index){
            setCurrentUnfoldedIndex(-1);
        }else
        {
            setCurrentUnfoldedIndex(index);
        }
    }

  useEffect(() => {
    if(!showSubMenuPreUnfolded) setShowSubMenuPreUnfolded(!showSubMenuPreUnfolded);
  }, [currentUnfoldedIndex]); // eslint-disable-line

    return (
        <Box>
            
            {Children.length > 0 ? ( 
            <Box>
                <Box> 
                    <Button width="100%" fontWeight={700} fontSize="md" justifyContent="space-between" py={3} _hover={{ textDecoration: "none" }} onClick={() => handleOnClickTopMenuItem(subMenuIndex)} variant="link">
                        {Title} {showSubMenuPreUnfolded && currentUnfoldedIndex === subMenuIndex ? <ChevronDownIcon boxSize={6} color={"#7a7a7a"}/> :<ChevronRightIcon boxSize={6} color={"#7a7a7a"}/>}
                    </Button>
                </Box>  
                <Box>
                <Box pb={4}>
                    {Children && Children.map((child, index) => (
                    <Box>
                        { showSubMenuPreUnfolded && currentUnfoldedIndex === subMenuIndex ?
                        <SubMenuItem key={index} {...child} setShowSubMenuItemPreUnfolded={setShowSubMenuItemPreUnfolded} />
                        : <></>
                        }
                    </Box>
                ))}
              </Box>
            </Box>
          
            </Box>
            ):( 
            <Button onClick={handleOnClickLeafMenuItem} variant="link" _hover={{ textDecoration: "none" }}>
              <NextLink href={Url} passHref>
                <Anchor display={"block"} py={3} _hover={{ textDecoration: "none" }} target={Target} >{Title}</Anchor>
              </NextLink>
            </Button>
            )}
        </Box>
    );
  };

  export default SubMenu;