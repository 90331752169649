import { useRouter } from "next/router";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { NavigationContextType } from "./Navigation.model";

const defaultNavValues = {
  Open: false,
  SearchOpen: false,
  Title: "",
  Url: "",
  Links: [],
};

const NavigationContext = React.createContext<
  [NavigationContextType, Dispatch<SetStateAction<NavigationContextType>>]
>([defaultNavValues, () => null]);

function NavigationProvider({ children }) {
  const [state, dispatch] = useState<NavigationContextType>(defaultNavValues);

  const { asPath } = useRouter();
  useEffect(() => {
    dispatch((state) => ({
      ...state,
      Open: false,
      SearchOpen: false,
      Links: [],
    }));
  }, [asPath]);

  return (
    <NavigationContext.Provider value={[state, dispatch]}>
      {children}
    </NavigationContext.Provider>
  );
}

export { NavigationProvider, NavigationContext };
