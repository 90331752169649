import { ReactComponent as LogoMobile } from "@assets/svg/logo-mobile.svg";
import { ReactComponent as Logo } from "@assets/svg/logo.svg";
import { Icon } from "@chakra-ui/icons";
import {
  Link as Anchor,
  Box,
  Container,
  GridItem,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import MotionBox from "@components/MotionBox.component";
import { useScrollDirection } from "@hooks/useScrollDirection";
import { useAnimation } from "framer-motion";
import NextLink from "next/link";
import React, { ReactElement, useEffect, useRef, useState } from "react";

import DesktopMenuComponent from "./DesktopMenu.component";
import FunctionsMenuComponent from "./FunctionsMenu.component";
import MainMenuComponent from "./MainMenu.component";
import MetaMenuComponent from "./MetaMenu.component";

function DesktopNavigationComponent({
  MainMenu,
  MetaMenu,
  Urls,
  isCheckout,
  showAnnouncement = false,
  updatePadTop,
  languageSelectorOpen,
  handleLanguageSelector,
  position,
  setPosition,
  languageSelectorRef,
  languageSelectorActive,
}): ReactElement {
  const scrollDirection = useScrollDirection();
  const [minimizeNav, setMinimizeNav] = useState(false);
  const isTablet = useBreakpointValue({ base: true, xl: false });
  const logoControls = useAnimation();
  const headerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const headerRect = headerRef.current?.getBoundingClientRect();
      const selectorRect = languageSelectorRef.current?.getBoundingClientRect();
      if (scrollDirection === "down") {
        if (languageSelectorOpen) {
          if (headerRect?.top < 0) {
            setMinimizeNav((prevMinimizeNav) =>
              !prevMinimizeNav ? true : prevMinimizeNav
            );
            setPosition((prevPosition) => {
              if (prevPosition === "relative") {
                updatePadTop({ base: 50, lg: 130 });
                return "fixed";
              }
              return prevPosition;
            });
          }
        } else {
          setMinimizeNav((prevMinimizeNav) =>
            !prevMinimizeNav ? true : prevMinimizeNav
          );
        }
      }

      if (scrollDirection === "up") {
        if (languageSelectorOpen) {
          if (selectorRect?.bottom >= 0) {
            setPosition((prevPosition) => {
              if (prevPosition === "fixed") {
                updatePadTop({ base: 0, lg: 0 });
                return "relative";
              }
              return prevPosition;
            });
          }
        }
        setMinimizeNav((prevMinimizeNav) =>
          prevMinimizeNav ? false : prevMinimizeNav
        );
      }

      if (scrollDirection) {
        logoControls.start(() => ({
          // opacity: [0, 1], // @todo disabled because it flickers
        }));
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollDirection, languageSelectorOpen]);

  return (
    <>
      <div ref={headerRef}>
        <MotionBox
          display="flex"
          alignItems="center"
          position={position}
          zIndex={10}
          width="100vw"
          top={showAnnouncement ? "40px" : 0}
          bg="white"
          initial={{ height: 130 }}
          animate={{
            height: minimizeNav ? [130, 55] : [55, 130],
          }}
          transition={{ duration: 0.25 }}
          onFocus={() => {
            // Wait for the click event to register.
            setTimeout(() => {
              setMinimizeNav(false);
            }, 100);
          }}
        >
          <Container paddingY={0} display="flex" alignItems="center">
            <SimpleGrid position="relative" columns={12} width="100%">
              <GridItem colSpan={2} my="auto">
                <NextLink href={Urls?.Home} passHref>
                  <Anchor width="100%">
                    <MotionBox
                      animate={logoControls}
                      transition={{ duration: 0.5 }}
                      display="flex"
                      justifyContent={minimizeNav ? "center" : "flex-start"}
                      className={"logo-header"}
                    >
                      {minimizeNav ? (
                        <Icon as={LogoMobile} width="130px" height="100%" />
                      ) : (
                        <Icon
                          as={Logo}
                          width={isTablet ? "172px" : "226px"}
                          height="100%"
                          mt={isTablet ? "-35px" : "-32px"}
                          ml={isTablet ? "-10px" : "-14px"}
                        />
                      )}
                    </MotionBox>
                  </Anchor>
                </NextLink>
              </GridItem>
              {!isCheckout && (
                <>
                  <GridItem
                    colSpan={8}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={"main-menu"}
                    pt={minimizeNav ? 0 : isTablet ? 1 : 4}
                  >
                    <MainMenuComponent {...MainMenu} />
                  </GridItem>

                  <GridItem colSpan={2} my="auto">
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      className={"functions"}
                      pt={minimizeNav ? 0 : 2}
                    >
                      <FunctionsMenuComponent />
                    </Box>
                  </GridItem>
                  <Box
                    position="absolute"
                    top={isTablet ? "-37px" : "-33px"}
                    right={0}
                    transform="auto"
                    zIndex={10}
                    className={"language-selector"}
                  >
                    <MetaMenuComponent
                      {...MetaMenu}
                      handleLanguageSelector={handleLanguageSelector}
                      languageSelectorActive={languageSelectorActive}
                    />
                  </Box>
                </>
              )}
            </SimpleGrid>
          </Container>
        </MotionBox>
      </div>
      <DesktopMenuComponent />
    </>
  );
}

export default DesktopNavigationComponent;
