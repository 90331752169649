export function getCookie(name) {
  if (typeof document === "undefined") return null; // Check if document is defined
  const cookies = document.cookie.split(";"); // Split cookies into an array
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim(); // Remove leading/trailing white space
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1); // Extract and return cookie value
    }
  }
  return null; // Cookie not found
}
