import { ChevronDownIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import MotionBoxComponent from "@components/MotionBox.component";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { CountryPickerType } from "../Navigation.model";
import styles from "./styles.module.css";

type CountryLanguagePickerComponentProps = CountryPickerType & {
  handleLanguageSelector: (open: boolean) => void;
  selectedCountry: string;
  setSelectedCountry: (country: string) => void;
  selectedLanguage: string;
  setSelectedLanguage: (language: string) => void;
  showAnnouncement: boolean;
};

function CountryLanguagePickerComponent({
  Countries,
  OverlayTitle,
  CountrySelectTitle,
  LanguageSelectTitle,
  handleLanguageSelector,
  selectedCountry,
  setSelectedCountry,
  selectedLanguage,
  setSelectedLanguage,
  showAnnouncement,
}: CountryLanguagePickerComponentProps) {
  const router = useRouter();

  const setLocaleCookie = (locale) => {
    document.cookie = `CHS_LOCALE=${locale}; max-age=31536000; path=/`;
    document.cookie = `NEXT_LOCALE=${locale
      .split("/")[0]
      .toLowerCase()}; max-age=31536000; path=/`;
  };

  const setCountryCodeCookie = (countryCode) => {
    document.cookie = `COUNTRY_CODE=${countryCode}; max-age=31536000; path=/`;
  };

  const handleClose = () => {
    handleLanguageSelector(false);
  };

  const splitLinkIntoLocale = (link) => {
    let parts = link.split('/');
    if(parts.length < 2){
      return link;
    }
    let result = parts.slice(0,2).join('/');
    return result;
  } 

  const handleCountrySelect = (country) => {
    if (country) {
      setSelectedCountry(country.CountryName);
      setCountryCodeCookie(country.CountryCode);
      const defaultLanguage = country.Languages.find((l) => l.IsDefault);
      if (defaultLanguage || country.Languages.length == 1) {
        const language = defaultLanguage || country.Languages[0];
        setSelectedLanguage(language.LanguageName);
        const locale = splitLinkIntoLocale(language.Link.Url);
        setLocaleCookie(locale);
        
        window.location.href = `/${language.Link.Url}`;
        // if (language.Link.Url.endsWith("en")) {
        //   window.location.href = `/${language.Link.Url}`;
        // } else {
        //   router.push(`/${language.Link.Url}`, "", { locale: language.Locale });
        //   handleClose();
        // }
      }
    }
  };

  const handleLanguageSelect = (language) => {
    if (language) {
      setSelectedLanguage(language.LanguageName);
      setLocaleCookie(language.Link.Url);

      window.location.href = `/${language.Link.Url}`;
      // if (language.Link.Url.endsWith("en")) {
      //   window.location.href = `/${language.Link.Url}`;
      // } else {
      //   router.push(`/${language.Link.Url}`, "", { locale: language.Locale });
      //   handleClose();
      // }
    }
  };
  let selectedCountryCode = selectedCountry
    ? Countries.find((c) => c.CountryName == selectedCountry)?.CountryCode
    : "";
  return (
    <Box
      rounded="md"
      w={useBreakpointValue({ base: "100%", lg: "75%" })}
      height="100%"
      position="relative"
      mx="auto"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      paddingTop={useBreakpointValue({ base: "150px", lg: null })}
    >
      <Text fontSize="4xl" fontWeight="bold" mb={8} align={"center"}>
        {OverlayTitle}
      </Text>

      <Flex
        justifyContent="space-between"
        mb={4}
        width={useBreakpointValue({ base: "100%", lg: "50%" })}
        flexDirection={useBreakpointValue({ base: "column", lg: "row" })}
      >
        <Box>
          <Text fontSize="2xl" fontWeight="bold" mb={2}>
            {CountrySelectTitle}
          </Text>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              width="100%"
            >
              <Box display="flex" alignItems="center">
                {selectedCountry ? (
                  <Image
                    width="25px"
                    src={
                      selectedCountryCode == "INT"
                        ? "/assets/flags/earth_flag.png"
                        : `https://assets.ipapi.com/flags/${selectedCountryCode.toLowerCase()}.svg`
                    }
                    alt={selectedCountry}
                    mr="2"
                  />
                ) : (
                  ""
                )}
                <Text fontSize={"16px"}>
                  {selectedCountry || "Select Country"}
                </Text>
              </Box>
            </MenuButton>
            <MenuList
              zIndex={20}
              sx={{ maxHeight: "275px", overflow: "hidden", overflowY: "auto" }}
              className={styles["language-selector-menu-list"]}
            >
              {Countries?.map((country, i) => (
                <MenuItem key={i} onClick={() => handleCountrySelect(country)}>
                  <Box display="flex" alignItems="center">
                    <Image
                      width="25px"
                      src={
                        country.CountryCode == "INT"
                          ? "/assets/flags/earth_flag.png"
                          : `https://assets.ipapi.com/flags/${country.CountryCode.toLowerCase()}.svg`
                      }
                      alt={country.CountryName}
                      mr="2"
                    />
                    <Text fontSize={"16px"}>{country.CountryName}</Text>
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Box>

        <Box paddingTop={useBreakpointValue({ base: "30px", lg: null })}>
          <Text fontSize="2xl" fontWeight="bold" marginBottom={"10px"}>
            {LanguageSelectTitle}
          </Text>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              width="100%"
              isDisabled={!selectedCountry}
            >
              <Box display="flex" alignItems="center">
                <Text fontSize={"16px"}>
                  {selectedLanguage || "Select Country"}
                </Text>
              </Box>
            </MenuButton>
            <MenuList
              zIndex={20}
              sx={{ maxHeight: "275px", overflow: "hidden", overflowY: "auto" }}
              className={styles["language-selector-menu-list"]}
            >
              {Countries?.find(
                (n) => n.CountryName == selectedCountry
              )?.Languages.map((language, i) => (
                <MenuItem
                  key={i}
                  onClick={() => handleLanguageSelect(language)}
                >
                  <Box display="flex" alignItems="center">
                    <Text fontSize={"16px"}>{language.LanguageName}</Text>
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Box>
      </Flex>

      <IconButton
        borderRadius="50%"
        variant="icon"
        icon={<SmallCloseIcon />}
        onClick={handleClose}
        aria-label="Close filter view"
        position="absolute"
        top={useBreakpointValue({ base: 2, lg: showAnnouncement ? 4 : 2 })}
        right={useBreakpointValue({ base: null, lg: 2 })}
        left={useBreakpointValue({ base: 2, lg: null })}
        mt={8}
      />
    </Box>
  );
}

export default CountryLanguagePickerComponent;
