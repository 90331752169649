import { Link as Anchor, Button, List, ListItem } from "@chakra-ui/react";
import NextLink from "next/link";
import { ReactElement } from "react";

import { MainMenuItemType, MainMenuType } from "./Navigation.model";
import { useNavigation } from "./useNavigation";

export function MenuItem({ Link, Children }: MainMenuItemType): ReactElement {
  const setLinks = useNavigation()[1];

  const handleClick = (): void => {
    setLinks((state) => {
      // To override the outside click event.
      const shouldStayOpen = state.Title !== Link.Title;
      return {
        ...state,
        Links: Children,
        Title: Link.Title,
        Url: Link.Url,
        Open: shouldStayOpen || !state.Open,
      };
    });
  };

  return (
    <ListItem key={Link.Title} paddingRight={4}>
      {Children.length > 0 ? (
        <Button className="menu-toggle" variant="link" onClick={handleClick}>
          {Link.Title}
        </Button>
      ) : (
        <NextLink href={Link.Url || ""} passHref>
          <Anchor>{Link.Title}</Anchor>
        </NextLink>
      )}
    </ListItem>
  );
}

function MainMenuComponent({ Links }: MainMenuType): ReactElement {
  return (
    <List variant="clean" display="flex" fontSize="sm" gap={{ base: 2, xl: 4 }}>
      {Links.map((menuItem, i) => {
        return <MenuItem key={i} {...menuItem} />;
      })}
    </List>
  );
}

export default MainMenuComponent;
