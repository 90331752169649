import getConfig from "@hooks/getConfig";
import { useAppData } from "@hooks/useAppData";
import fetch from "cross-fetch";
import { useEffect, useState } from "react";
import { useLocalstorageState } from "rooks";

function useFavorites() {
  const { edgeEndpoint } = getConfig();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const [favoriteKeypair, setFavoriteKeypair] = useLocalstorageState(
    "CHS-Favorites",
    null
  );

  const [appData, setAppData] = useAppData();

  useEffect(() => {
    if (!favoriteKeypair) {
      (async () => {
        await getKeys();
      })();
    }
  }, []);

  useEffect(() => {
    if (data) setAppData({ ...appData, favorites: data });
  }, [data]);

  const getKeys = async () => {
    setLoading(true);
    try {
      const res = await fetch([edgeEndpoint, "favorites", "keys"].join("/"));
      const json = await res.json();
      const { privateAccessKey, publicAccessKey } = json;
      setFavoriteKeypair({ privateAccessKey, publicAccessKey });
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const url = [edgeEndpoint, "favorites"].join("/");
  const getFavorites = async () => {
    setLoading(true);
    if (!favoriteKeypair) await getKeys();
    const urlParams = new URLSearchParams({
      accessKey: favoriteKeypair?.privateAccessKey,
    }).toString();
    try {
      const res = fetch([url, urlParams].join("?"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await (await res).json();
      setData(json);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const upsertFavorite = async (
    favoriteId: string,
    favoriteType: string = "1",
    shouldDelete: boolean = false
  ) => {
    setLoading(true);
    if (!favoriteKeypair) await getKeys();
    const urlParams = new URLSearchParams({
      privateAccessKey: favoriteKeypair.privateAccessKey,
      favoriteId,
      favoriteType,
    }).toString();
    try {
      const res = await fetch([url, urlParams].join("?"), {
        method: shouldDelete ? "DELETE" : "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const json = await res.json();
      setData(json);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return { getFavorites, upsertFavorite, data, loading, error };
}

export default useFavorites;
