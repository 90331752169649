import { Link as Anchor, List, ListItem } from "@chakra-ui/react";
import NextLink from "next/link";
import { ReactElement } from "react";

import CountryPickerComponent from "./CountryPicker.component";
import { MetaMenuType } from "./Navigation.model";

type MetaMenuComponentProps = MetaMenuType & {
  handleLanguageSelector: (open: boolean) => void;
  languageSelectorActive: boolean;
};

function MetaMenuComponent({
  Links,
  CountryPicker,
  handleLanguageSelector,
  languageSelectorActive,
}: MetaMenuComponentProps): ReactElement {
  return (
    <List fontSize="xs" display="flex">
      {Links &&
        Links.map((MenuItem, i) => {
          return (
            <ListItem
              key={i}
              marginX={1}
              _after={{ content: "'|'", marginLeft: 2 }}
            >
              <NextLink href={MenuItem.Url} passHref>
                <Anchor target={MenuItem.Target}>{MenuItem.Title}</Anchor>
              </NextLink>
            </ListItem>
          );
        })}
      <ListItem ml={1} mr={2}>
        <CountryPickerComponent
          {...CountryPicker}
          handleLanguageSelector={handleLanguageSelector}
          languageSelectorActive={languageSelectorActive}
        />
      </ListItem>
    </List>
  );
}

export default MetaMenuComponent;
