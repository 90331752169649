import {
  Link as Anchor,
  Box,
  Container,
  Flex,
  GridItem,
  Heading,
  List,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import MotionBox from "@components/MotionBox.component";
import { useAppData } from "@hooks/useAppData";
import { useOnOutsideClick } from "@hooks/useOutsideClick";
import SearchResultCard from "@modules/Search/SearchResultCard";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { MenuItem } from "./MainMenu.component";
import { useNavigation } from "./useNavigation";

function DesktopMenuComponent() {
  const router = useRouter();

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuClosing, setMenuClosing] = useState(false);
  const showFeatured = useBreakpointValue({ base: false, lg: true });

  const [{ Links, Open, Title, Url }, setMenu] = useNavigation();
  const [
    {
      marketData: { Featured },
    },
  ] = useAppData();
  const { Product, Designer } = Featured;
  const transitionTime = 300;

  // Close on outside click.
  const { innerBorderRef } = useOnOutsideClick((): void => {
    closeMenu();
  });

  const closeMenu = () => {
    setMenuClosing(true);
    setTimeout(() => {
      setMenuOpen(false);
      setMenuClosing(false);
      setMenu((state) => ({ ...state, Open: false }));
    }, transitionTime);
  };

  useEffect(() => {
    if (!menuOpen && !menuClosing) {
      setMenuOpen(Open);
    }

    if (menuOpen && !menuClosing) {
      closeMenu();
    }
  }, [Open, router.asPath]); // eslint-disable-line

  return (
    menuOpen && (
      <>
        <MotionBox
          initial={{ translateY: !menuOpen ? "0%" : "-100%" }}
          animate={{
            translateY: menuClosing ? ["0%", "-100%"] : ["-100%", "0%"],
          }}
          transition={{
            duration: transitionTime / 1000,
            ease: "easeInOut",
          }}
          zIndex={5}
          width="100%"
          left={0}
          top={130}
          position="fixed"
          borderTop="1px"
          borderColor="brand.gray.300"
          backgroundColor="brand.gray.100"
        >
          <Container paddingY={0} ref={innerBorderRef}>
            <SimpleGrid columns={{ base: 9, md: 9, lg: 12 }}>
            {Links.length <= 2 ? (
              <>
              <GridItem colSpan={6} py={10} bgColor="brand.gray.100">
                <List variant="clean" ref={innerBorderRef} fontSize="sm">
                  <SimpleGrid columns={12} gap={3}>
                    <GridItem colSpan={12} key={Links[0]?.Link.Title} paddingBottom={7}>
                      <Heading as="h2" size="sm" fontWeight="bold" >{Links[0]?.Link.Title}</Heading>
                    </GridItem>
                    <GridItem colSpan={12} key={Links[0]?.Link.Title}>
                      <Box style={{ columnCount: "3"}}>
                        {Links[0]?.Children.map((link) => {
                          return (
                            <>
                              {link.Children.length > 0 ? (
                                <Flex key={link.Link.Title} gap='2' flexDirection={"column"} mb={8} style={{breakInside: "avoid"}} >
                                  <Heading as="h2" paddingBottom={2} size="xs" fontWeight="bold">{link.Link.Title}</Heading>
                                  {link.Children.map((child) => {
                                    return (
                                      <MenuItem {...child} />
                                    )
                                  })}</Flex>) : <></>}
                            </>
                          )
                        })}
                        <Flex gap='2' flexDirection={"column"} style={{breakInside: "avoid"}} >
                          {Links[0]?.Children.map((link) => { return (                              
                           <> {link.Children.length < 1 ? (
                              <MenuItem {...link} />
                            ) : <></>}                            
                            </>)
                          })}
                        </Flex>
                      </Box>
                    </GridItem>
                  </SimpleGrid>
                </List>
              </GridItem>
              <GridItem colSpan={3} py={10} pl={8} borderLeft='4px' borderColor='white'>
                <List variant="clean" ref={innerBorderRef} fontSize="sm">
                  <SimpleGrid columns={12} gap={2}>
                    <GridItem colSpan={12} key={Links[1]?.Link.Title} paddingBottom={7}>
                      <Heading as="h2" size="sm" fontWeight="bold" >{Links[1]?.Link.Title}</Heading>
                    </GridItem>
                    {Links[1]?.Children.map((link) => {
                      return (
                        <GridItem colSpan={12} key={link.Link.Title}>
                          {link.Children.length > 0 ? (
                            link.Children.map((child) => {
                              return (
                                <>
                                  <Heading as="h2" size="sm" fontWeight="bold">{child.Link.Title}</Heading>
                                  <MenuItem {...child} />
                                </>
                              )
                            })) : (
                            <MenuItem {...link} />
                          )}
                        </GridItem>
                      );
                    })}
                  </SimpleGrid>
                </List>
              </GridItem>
              </>):
              <GridItem colSpan={9} py={10} bgColor="brand.gray.100">
                <NextLink href={Url || ""} passHref>
                  <Anchor>
                    <Heading
                      as="h2"
                      size="sm"
                      fontWeight="700"
                      marginRight={4}
                      marginBottom={5}
                    >
                      {Title}
                    </Heading>
                  </Anchor>
                </NextLink>
                <List variant="clean" ref={innerBorderRef} fontSize="sm">
                  <SimpleGrid columns={12} gap={2}>
                    {Links.map((link) => {
                      return (
                        <GridItem colSpan={4} key={link.Link.Title}>
                          <MenuItem {...link} />
                        </GridItem>
                      );
                    })}
                  </SimpleGrid>
                </List>
              </GridItem>
            }            
              {showFeatured && Product ? (
                <GridItem colSpan={3} px={10} py={10} bgColor="brand.white">
                  <NextLink href={""} passHref>
                    <Anchor>
                      <Heading
                        as="h2"
                        size="sm"
                        fontWeight="700"
                        marginRight={4}
                        marginBottom={5}
                      >
                        FEATURED
                      </Heading>
                    </Anchor>
                  </NextLink>
                  <SearchResultCard
                    {...Product}
                    model={Product}
                    variantImageSize={Product.imageSize}
                    variantPageUrl={Product.pageUrl}
                    price={Product.price || Product.formattedPrice}
                    formattedPrice={Product.formattedPrice}
                    purchaseNotEnabled={Product.purchaseNotEnabled}
                  />
                </GridItem>
              ) : (
                <></>
              )}
            </SimpleGrid>     
          </Container>
        </MotionBox>
        <MotionBox
          position="fixed"
          height="100vh"
          width="100vw"
          left={0}
          top={0}
          zIndex={2}
          backgroundColor="black"
          initial={{ opacity: 0 }}
          animate={{
            opacity: menuClosing ? [0.4, 0] : [0, 0.4],
          }}
          transition={{
            duration: transitionTime / 1000,
          }}
        />
      </>
    )
  );
}

export default DesktopMenuComponent;
