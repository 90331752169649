import { useBreakpointValue } from "@chakra-ui/react";
import SearchOverlay from "@modules/Navigation/SearchOverlay.component";

import DesktopNavigation from "./DesktopNavigation.component";
import MobileNavigation from "./MobileNavigation.component";
import { NavigationProvider } from "./Navigation.context";

function Navigation(props) {
  const NavigationVariant = useBreakpointValue({
    base: MobileNavigation,
    lg: DesktopNavigation,
  });

  return (
    <NavigationProvider>
      <NavigationVariant {...props} />
      <SearchOverlay />
    </NavigationProvider>
  );
}

export default Navigation;
