import { PriceListType } from "@context/AppContext/App.model";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export interface ClientSideState {
  priceListData: PriceListType;
  currentProductIds?: string[];
  currentCategoryIds?: string[];
  currentDesignerId?: string;
  currentDesignerName?: string;
}

export interface ClientSideStateHandler extends ClientSideState {
  setPriceListData(v: PriceListType): any;
  setCurrentProductIds(v: string[]): any;
  setCurrentCategoryIds(v: string[]): any;
  setCurrentDesignerId(v: string): any;
  setCurrentDesignerName(v: string): any;
}

export const initialState: ClientSideState = {
  priceListData: null,
  currentProductIds: null,
  currentCategoryIds: null,
  currentDesignerId: null,
  currentDesignerName: null
};

export const useClientSideState = create<ClientSideStateHandler>()(
  devtools((set, get) => ({
    ...initialState,

    reset: () => set(initialState),

    setPriceListData: (v: PriceListType) => set({ priceListData: v }),
    setCurrentProductIds: (v: string[]) => set({ currentProductIds: v }),
    setCurrentCategoryIds: (v: string[]) => set({ currentCategoryIds: v }),
    setCurrentDesignerId: (v: string) => set({ currentDesignerId: v }),
    setCurrentDesignerName: (v: string) => set({ currentDesignerName: v })
  }))
);