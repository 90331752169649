import { ReactComponent as CheckMark } from "@assets/svg/Checkmark.svg";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
} from "@chakra-ui/react";
import { Field } from "formik";

interface FormFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  component?: React.FC;
  validate?: React.FC;
  type?: React.HTMLInputTypeAttribute;
  autoComplete?: string;
  disabled?: boolean;
  value?: string;
  validateCheckMark?: boolean;
  width?: string;
  marginTop?: string;
}

export const FormField = ({
  name,
  label,
  placeholder,
  component = Input,
  validate,
  type = "text",
  autoComplete,
  disabled,
  value,
  validateCheckMark,
  width,
  marginTop,
}: FormFieldProps) => {
  const isCheckox = type === "checkbox";
  return (
    <Field name={name} validate={validate}>
      {({ field, meta }) => (
        <FormControl
          isInvalid={meta.error && meta.touched}
          isRequired={!!validate}
          display={isCheckox ? "flex" : "unset"}
          variant={isCheckox ? "" : "floating"}
        >
          {isCheckox && label ? (
            <FormLabel my={2} ml={type === "checkbox" ? 2 : 0}>
              {label}
            </FormLabel>
          ) : (
            <></>
          )}

          <Field
            as={component}
            {...field}
            type={type}
            placeholder=" "
            focusBorderColor="black"
            autoComplete={autoComplete}
            value={value}
            disabled={disabled}
            paddingRight="10"
            borderRadius={0}
            width={width}
          />
          {!isCheckox && label ? (
            <FormLabel my={marginTop ?? 2}>{label}</FormLabel>
          ) : (
            <></>
          )}

          {/* condition test for field has been touched without error */}
          {validateCheckMark && !meta.error && meta.touched ? (
            <Icon
              as={CheckMark}
              position="absolute"
              right="4"
              top="50%"
              transform="translateY(-50%)"
            />
          ) : (
            <></>
          )}
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
