import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";

import { AppContextType } from "./App.model";

const AppContext = React.createContext<
  [AppContextType, Dispatch<SetStateAction<AppContextType>>]
>([
  { marketData: null, staticData: null, favorites: [], basket: null },
  () => null,
]);

function AppProvider({
  children,
  staticData,
  marketData,
  favorites,
  basket,
}: PropsWithChildren<AppContextType>) {
  const [state, dispatch] = useState<AppContextType>({
    staticData,
    marketData,
    favorites,
    basket,
  });
  return (
    <AppContext.Provider value={[state, dispatch]}>
      {children}
    </AppContext.Provider>
  );
}

export { AppProvider, AppContext };
