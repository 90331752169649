import { ImageLoader, ImageProps as NextImageProps } from "next/image";

export type ImageAspectRatiosProps = keyof typeof ImageAspectRatios;
export type ImageSizesProps = keyof typeof ImageSizes;

export const ImageAspectRatios = {
  "9:16": 9 / 16,
  "3:4": 3 / 4,
  "5:6": 5 / 6,
  "16:9": 16 / 9,
  "3:2": 3 / 2,
  "2:3": 2 / 3,
  "4:5": 4 / 5,
  "1:1": 1,
  "4:3": 4 / 3,
};

// From SPA site: export type aspectRatio = "1:1" | "2:1" | "4:3" | "7:3" | "16:9";

export const ImageSizes = {
  full: { factor: 1, isContained: false },
  large: { factor: 1, isContained: true },
  medium: { factor: 0.5, isContained: true },
  small: { factor: 0.25, isContained: true },
};

export interface ImageComponentType {
  src: string;
  aspectRatio: ImageAspectRatiosProps;
  alt: string;
  sizes: string;
  size?: ImageSizesProps;
  objectFit?: NextImageProps["objectFit"];
  priority?: boolean;
  quality?: number;
  crop?: boolean;
  focalPoint?: string;
  loader?: ImageLoader;
  style?: React.CSSProperties;
  aspectRatioStyle?: React.CSSProperties;
}
