import { SmallCloseIcon } from "@chakra-ui/icons";
import { Box, Button, Container, GridItem, IconButton, Input, SimpleGrid, Spinner, Text, Flex } from "@chakra-ui/react";
import MotionBoxComponent from "@components/MotionBox.component";
import { useAppData } from "@hooks/useAppData";
import { useOnOutsideClick } from "@hooks/useOutsideClick";
import { useInitialSearchRecommendations } from "@hooks/useRecommendations";
import useSearch from "@hooks/useSearch";
import { useNavigation } from "@modules/Navigation/useNavigation";
import SearchResultCard from "@modules/Search/SearchResultCard";
import { useClientSideState } from "@zustand/clientSideState";
import { useAnimation } from "framer-motion";
import { useLocale, useTranslations } from "next-intl";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "rooks";

function SearchOverlay() {
  const { priceListData } = useClientSideState();
  const [{ marketData: { HelloRetailInitialSearchContentStrategy } }] = useAppData();
  const dict = useTranslations("Search");
  const locale = useLocale();
  const [searchTerm, setSearchTerm] = useState("");
  const { search, data, loading, error, clear } = useSearch(locale);
  const setSearchTermDebounce = useDebounce(setSearchTerm, 500);
  const { recommend, finalProducts } = useInitialSearchRecommendations({
    helloRetailRecomKey: HelloRetailInitialSearchContentStrategy,
    defaultProducts: null,
    helloRetailSplashFilter: null,
  });

  const [{ marketData }] = useAppData();
  const { PopularSearchTerms } = { ...marketData.SiteSearch };

  const [{ SearchOpen }] = useNavigation();

  const [navData, setNavData] = useNavigation();
  const handleSearch = () => {
    setNavData({ ...navData, SearchOpen: !navData.SearchOpen });
  };

  const { innerBorderRef } = useOnOutsideClick((): void => {
    SearchOpen && handleSearch();
  });

  const animationControls = useAnimation();
  const [showSearch, setShowSearch] = useState(false);
  const inputRef = useRef(null);

  const handleAnimationComplete = (open) => {
    if (open) {
      inputRef.current.focus();
    } else {
      setShowSearch(false);
      inputRef.current.value = "";
      clear();
    }
  };

  useEffect(() => {
    recommend().catch(console.error);
    if (searchTerm.length > 0) {
      search({ searchTerm }).catch(console.error);
    } else if (data && searchTerm.length === 0) {

      clear();
    }
  }, [searchTerm, priceListData]);

  useEffect(() => {
    if (SearchOpen) {
      setShowSearch(true);
      animationControls
        .start(() => ({
          opacity: [0, 1],
          translateY: ["-10%", "0%"],
        }))
        .catch(console.error);
    } else {
      animationControls
        .start(() => ({
          opacity: [1, 0],
          translateY: ["0%", "-10%"],
        }))
        .catch(console.error);
    }
  }, [SearchOpen]);

  if (error) {
    console.log(error);
    return <></>;
  }

  return (
    <MotionBoxComponent
      visibility={showSearch ? "visible" : "hidden"}
      position="fixed"
      width="100vw"
      height="100vh"
      overflowY="auto"
      zIndex={9}
      top={0}
      bottom={0}
      bg="white"
      initial={{ opacity: 0, translateY: "-10%" }}
      animate={animationControls}
      transition={{
        opacity: { duration: 0.35 },
        translateY: { duration: 0.5 },
      }}
      onAnimationComplete={() => {
        handleAnimationComplete(SearchOpen);
      }}
    >
      <Container marginTop={{ base: 50, lg: 130 }} position="relative" ref={innerBorderRef}>
        <IconButton position="absolute" top={2} right={5} borderRadius="50%" variant="icon" icon={<SmallCloseIcon />} onClick={handleSearch} aria-label="Close filter view" />
        <Input
          ref={inputRef}
          marginTop={4}
          placeholder={dict("InputPlaceholderSearchDesktop")}
          onChange={(e) => {
            setSearchTermDebounce(e.target.value);
          }}
        />
        {loading && <Spinner position="absolute" top={10} left={5} mr={2} />}
        {PopularSearchTerms && (
          <Box mt={2} mb={4}>
            <Text>
              {dict("TextMostPopular")}:
              {PopularSearchTerms.map((term, idx) => (
                <Box as="span" key={term.replace(" ", "_")}>
                  {idx !== 0 && "⸱"}
                  <Button
                    variant="link"
                    onClick={() => {
                      setSearchTerm(term);
                    }}
                    mx={1}
                  >
                    {term}
                  </Button>
                </Box>
              ))}
            </Text>
          </Box>
        )}

        {data && (
          <SimpleGrid columns={{ base: 4, md: 6, lg: 6 }} gap={5}>
            {data
              .filter((variant) => typeof variant.frontImageUrl !== "undefined")
              .map((variant, index) => {
                return (
                  <GridItem key={index} colSpan={variant.variantImageSize === "wide" ? 3 : 2}>
                    <SearchResultCard {...variant} OnClick={handleSearch} hidePrice isSearchPage={true} />
                  </GridItem>
                );
              })}
          </SimpleGrid>
        )}
        {/* only show recommendedProducts if there is no active search results */}
        {finalProducts && !data && (
          <SimpleGrid columns={{ base: 4, md: 6, lg: 6 }} gap={5}>
            {finalProducts
              .filter((variant) => typeof variant.frontImageUrl !== "undefined")
              .map((variant, index) => {
                return (
                  <GridItem key={index} colSpan={variant.variantImageSize === "wide" ? 3 : 2}>
                    <SearchResultCard {...variant} OnClick={handleSearch} hidePrice isSearchPage={true} />
                  </GridItem>
                );
              })}
          </SimpleGrid>
        )}
      </Container>
    </MotionBoxComponent>
  );
}

export default SearchOverlay;
