import { Box, Stack, useBreakpointValue } from "@chakra-ui/react";
import Footer from "@components/Footer/Footer.component";
import MotionBoxComponent from "@components/MotionBox.component";
import { isCheckoutPage } from "@hooks/isCheckoutPage";
import { useOnOutsideLanguageSelectorClick } from "@hooks/useOutsideClick";
import {
  AnnouncementBar,
  AnnouncementProps,
} from "@modules/AnnouncementBar/AnnouncementBar.component";
import Navigation from "@modules/Navigation";
import CountryLanguagePickerComponent from "@modules/Navigation/CountryLanguagePicker/CountryLanguagePicker.component";
import { useAnimation } from "framer-motion";
import { useRouter } from "next/router";
import { ReactElement, useEffect, useRef, useState } from "react";

function DefaultLayout({
  children,
  marketData,
  Kind,
  Announcement = { Show: false, Announcement: "" },
  selectedCountry,
  setSelectedCountry,
  selectedLanguage,
  setSelectedLanguage,
}): ReactElement {
  const isCheckout = isCheckoutPage(Kind);
  const [announcement, setAnnouncement] =
    useState<AnnouncementProps>(Announcement);

  useEffect(() => {
    const fetchData = async () => {
      const edgeConfigRes = await fetch("/api/getEdgeConfig");
      const { showAnnouncement, announcement } = await edgeConfigRes.json();
      if (showAnnouncement) {
        setAnnouncement({
          Show: showAnnouncement,
          Announcement: announcement,
        });
      }
    };
    fetchData();
  }, []);

  const [padTop, setPadTop] = useState({ base: 50, lg: 130 });
  // Retrieve the breakpoint value using useBreakpointValue
  const calculatedPadTop = useBreakpointValue(padTop);
  const updatePadTop = (newValue) => {
    setPadTop(newValue);
  };
  const [visible, setVisible] = useState(false);
  const [isStart, setIsStart] = useState(true);
  const { events } = useRouter();
  const animationControls = useAnimation();

  const initialRender = useRef(true); // Define a reference to track the initial render
  const [languageSelectorActive, setLanguageSelectorActive] = useState(false);
  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);
  const [position, setPosition] = useState("fixed"); // header position
  const [opacity, setOpacity] = useState(0); // header position
  const shouldDisplay = useBreakpointValue({ base: false, lg: true });

  // Close on outside click.
  const { languageSelectorRef } = useOnOutsideLanguageSelectorClick(
    (): void => {
      handleLanguageSelector(false);
    }
  );

  if (typeof events !== "undefined") {
    events.on("routeChangeStart", () => {
      setVisible(true);
      setIsStart(true);
      animationControls.start({ opacity: [0, 1] });
    });

    events.on("routeChangeComplete", () => {
      setIsStart(false);
      animationControls.start({
        opacity: [1, 0],
      });
    });
  }

  const handleOnAnimationComplete = () => {
    if (!isStart) {
      setVisible(false);
    }
  };

  const handleLanguageSelector = (open: boolean) => {
    if (open) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setOpacity(1);
      setPosition("relative");
      setLanguageSelectorActive(true);
      updatePadTop({ base: 0, lg: 0 });
    } else {
      setLanguageSelectorActive(false);
    }
  };

  const handleLanguageSelectorAnimationComplete = () => {
    if (initialRender.current) {
      // Skip the callback on the initial render
      initialRender.current = false;
    } else {
      // Execute the callback on subsequent renders
      setLanguageSelectorOpen(!languageSelectorOpen);
      if (languageSelectorOpen) {
        setOpacity(0);
        setPosition("fixed");
        updatePadTop({ base: 50, lg: 130 });
      }
    }
  };
  return (
    <>
      {shouldDisplay && (
        <div ref={languageSelectorRef}>
          <MotionBoxComponent
            bg="white"
            color="black"
            fontWeight="semibold"
            position="relative"
            w="100%"
            initial={{ height: 0 }}
            animate={{ height: languageSelectorActive ? 300 : 0 }}
            transition={{ height: { duration: 0.35 } }}
            opacity={opacity}
            onAnimationComplete={handleLanguageSelectorAnimationComplete}
          >
            <CountryLanguagePickerComponent
              {...marketData.MetaMenu?.CountryPicker}
              handleLanguageSelector={handleLanguageSelector}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          </MotionBoxComponent>
        </div>
      )}
      <Box paddingTop={calculatedPadTop}>
        <AnnouncementBar {...announcement} />
        <Navigation
          {...marketData}
          isCheckout={isCheckout}
          showAnnouncement={announcement.Show}
          updatePadTop={updatePadTop}
          languageSelectorOpen={languageSelectorOpen}
          languageSelectorActive={languageSelectorActive}
          position={position}
          setPosition={setPosition}
          languageSelectorRef={languageSelectorRef}
          handleLanguageSelector={handleLanguageSelector}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
        <Box as="main" position="relative">
          <MotionBoxComponent
            position="absolute"
            bg="white"
            height="100%"
            width="100%"
            zIndex={2}
            animate={animationControls}
            transition={{ opacity: { duration: 0.35 } }}
            visibility={visible ? "visible" : "hidden"}
            onAnimationComplete={handleOnAnimationComplete}
          />
          {children}
        </Box>
        {!isCheckout && <Footer {...marketData} />}
      </Box>
    </>
  );
}

export default DefaultLayout;
