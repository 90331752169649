import { ProductType } from "@components/Pages/ProductDisplay.model";
import { BasketLine } from "@modules/Basket/Basket.model";
import { SearchVariantModel } from "@modules/Search/Search.model";

import { useAppData } from "./useAppData";

// const refObject = {
//   item_id: item.sku,
//   item_name: `${item.model.modelName} | ${item.model.friendlyModelName}`,
//   // affiliation: "Google Merchandise Store",
//   // coupon: "SUMMER_FUN",
//   // currency: "USD",
//   // discount: item.ListedPrice - item.Price,
//   index: idx + 1,
//   // item_brand: "Google",
//   item_category: item.model.category,
//   // item_category2: "Adult",
//   // item_category3: "Shirts",
//   // item_category4: "Crew",
//   // item_category5: "Short sleeve",
//   // item_list_id: "related_products",
//   // item_list_name: "Related Products",
//   // item_variant: item.,
//   // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//   price: item.price,
//   // quantity: 1,
// }));

function useTracking() {
  const [{ basket, marketData }] = useAppData();
  const { CurrencyCode, SiteSearch } = marketData;
  const { PopularSearchTerms } = { ...SiteSearch };

  const addClearEvent = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  };

  const trackViewList = (products: SearchVariantModel[]) => {
    if (products) {
      addClearEvent();
      const items = products.map((item, index) => ({
        index,
        item_id: item.sku,
        item_name: `${item.model.modelName} | ${item.model.friendlyModelName}`,
        item_category: item.model.category,
        item_list_name: item.model.category,
        price: Number(item.formattedLowestPrice.replace(".", "")),
      }));
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items,
        },
      });
    }
  };

  const trackViewItem = (item: ProductType, ProductName: string, CanonicalUrl: string) => {
    addClearEvent();
    const items = [
      {
        item_id: item.Sku,
        item_name: ProductName,
        item_category: item.Category,
        item_variant: item.FormattedConfiguration,
        price: item.Price,
      },
    ];
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: CurrencyCode,
        value: item.Price,
        items,
      },
    });

    if(item.Sku && item.PageUrl && CanonicalUrl && typeof window !== "undefined") {
      const canonicalUrlParts = CanonicalUrl.split("/");
      const variantUrlParts = item.PageUrl.split("/");
      const newUrl = [...canonicalUrlParts.slice(0, 7), ...variantUrlParts.slice(5)].join("/");
      window.hrq = window.hrq || [];
      const helloRetailProductPageView = [
        "trackPageView",
        newUrl
      ];
      window.hrq.push(helloRetailProductPageView)
    }
  };

  const trackSearch = (searchTerm: string, numResults: number) => {
    addClearEvent();
    window.dataLayer.push({
      event: "search",
      term: searchTerm,
      results: numResults,
      search_suggestion: PopularSearchTerms.includes(searchTerm),
    });
  };

  const trackAddRemoveBasket = (item_id: string, lines: BasketLine[]) => {
    addClearEvent();
    const oldItem = basket?.Lines.find((item) => item.VariantId === item_id);
    const item = lines.find((item) => item.VariantId === item_id);
    const initialQuantity = oldItem?.Quantity || 0;
    const newQuantity = item?.Quantity || 0;
    const quantityChange = newQuantity - initialQuantity;
    const items = [
      {
        item_id,
        item_name: item.Name,
        item_category: item.Category,
        item_variant: item.Description,
        price: item.PriceValue,
        discount: item.Discount,
        quantity: quantityChange,
      },
    ];
    window.dataLayer.push({
      event: quantityChange > 0 ? "add_to_cart" : "remove_from_cart",
      ecommerce: {
        currency: CurrencyCode,
        value: item.PriceValue,
        items,
      },
    });

    // track hello retail cart
    if (typeof window !== "undefined") {
      window.hrq = window.hrq || [];
      let priceValueSum = 0;
      for (let line of lines) {
        priceValueSum += line.TotalPriceValue;
      }
      const helloRetailCart = [
        "setCart",
        {
          total: priceValueSum,
          productNumbers: lines.map(function (v) {
            return v.VariantId;
          }),
          function() {
            console.debug("Hello retail cart has been tracked");
          },
        },
      ];
      window.hrq.push(helloRetailCart);
    }
  };

  const trackBeginCheckout = () => {
    addClearEvent();
    const items = basket.Lines.map((item) => ({
      item_id: item.VariantId,
      item_name: item.Name,
      item_category: item.Category,
      item_variant: item.Description,
      price: item.PriceValue,
      discount: item.Discount,
      coupon: basket.OrderDraftVoucher?.Name,
      quantity: item.Quantity,
    }));

    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: CurrencyCode,
        value: basket.TotalPriceValue,
        items,
      },
    });
  };
  const trackCompletedPurchase = (json) => {
    addClearEvent();
    const items = json?.Order.Lines.map((item) => ({
      item_id: item.VariantId,
      item_name: item.Name,
      item_category: item.Category,
      item_variant: item.Configuration,
      price: item.ItemPriceValue,
      discount: item.ItemDiscountValue,
      quantity: item.Quantity,
    }));

    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: json.OrderId,
        currency: json.CurrencyCode,
        value: json.Order.TotalPriceValue,
        shipping: json.Order.ShippingPriceValue,
        items,
      },
    });

    // track hello retail conversion
    if (json && typeof window !== "undefined") {
      window.hrq = window.hrq || [];
      const helloRetailConversion = [
        "trackConversion",
        {
          total: json.Order.TotalPriceValue,
          orderNumber: json.OrderId,
          email: json.InvoiceAddress.Email,
          productNumbers: json.Order.Lines.map(function (v) {
            return v.VariantId;
          }),
          function() {
            console.debug("Hello retail conversion has been tracked");
          },
        },
      ];
      window.hrq.push(helloRetailConversion);
    }
  };

  const trackProductClick = (trackingCode: string) => {
    if(trackingCode && typeof window !== "undefined") {
      window.hrq = window.hrq || [];
      const helloRetailClick = [
        "trackClick",
        trackingCode
      ];
      window.hrq.push(helloRetailClick);
    }
  }

  return {
    trackViewItem,
    trackViewList,
    trackAddRemoveBasket,
    trackBeginCheckout,
    trackCompletedPurchase,
    trackSearch,
    trackProductClick
  };
}

export default useTracking;
