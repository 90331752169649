import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";
import { GenericCarousel } from "@components/GenericCarousel";
import MotionBox from "@components/MotionBox.component";
import getPriceFormat from "@hooks/getPriceFormat";
import { useAppData } from "@hooks/useAppData";
import useBasket from "@hooks/useBasket";
import { useAnimation } from "framer-motion";
import { useTranslations } from "next-intl";
import NextLink from "next/link";
import { useEffect } from "react";
import { useInViewRef } from "rooks";

import MiniBasketLine from "./MiniBasketLine/MiniBasketLine.component";

function MiniBasket() {
  const animationControls = useAnimation();
  const [inViewRef, inView] = useInViewRef();

  useEffect(() => {
    animationControls.start({
      opacity: [0.3, 1],
      translateY: ["-10", "0%"],
    });
  }, [inView]);

  const dict = useTranslations("MiniBasket");
  const { getBasket, checkSoldOutItems, isInvalidBasket } = useBasket();

  const [
    {
      basket,
      marketData: { Currency, Urls },
    },
  ] = useAppData();

  useEffect(() => {
    checkSoldOutItems(basket);
  }, [basket]);

  useEffect(() => {
    getBasket();
  }, []);

  const noItems = basket === null || !basket?.Lines;

  return (
    <Box position="fixed" width="100vw" left={0} ref={inViewRef} bg="white">
      <MotionBox
        initial={{ translateY: "-10", opacity: 0.3 }}
        animate={animationControls}
        height="100%"
        transition={{ duration: 0.25 }}
      >
        <Box position="fixed" width="100vw" left={0} bg="white" boxShadow="lg">
          {!noItems && basket.Lines.length > 0 && (
            <Container paddingBottom={0} pt={10}>
              <GenericCarousel
                perPage={3}
                perMove={1}
                controls
                ariaLabel="MiniBasketCarousel"
                position={{ top: -4, right: 0 }}
              >
                {basket.Lines.map((line, idx) => (
                  <MiniBasketLine
                    {...line}
                    currency={Currency}
                    key={`${idx} - ${line.Name}`}
                  />
                ))}
              </GenericCarousel>
            </Container>
          )}
          <Box bg="brand.gray.300">
            {!noItems && basket?.Lines.length > 0 ? (
              <Container py={4}>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text>
                    {dict("TextCartCarouselDescription", {
                      0: basket.Lines.length,
                    })}
                  </Text>
                  <Flex gap={6}>
                    <Flex
                      flexDirection="column"
                      width="240px"
                      justifyContent="flex-end"
                    >
                      <Flex justifyContent="space-between" padding={1}>
                        <Text fontWeight="bold">{dict("TextPriceTotal")}</Text>
                        <Text fontWeight="bold">
                          {getPriceFormat(basket.TotalPrice, Currency)}
                        </Text>
                      </Flex>
                      <Divider borderColor="brand.gray.700" />
                    </Flex>
                    <NextLink href={Urls.CheckoutBasket || "/basket"} passHref>
                      <Link>
                        <Button float="right" fontWeight={500}>
                          {dict("ButtonShoppingCart")}
                        </Button>
                      </Link>
                    </NextLink>
                    <NextLink href={Urls.CheckoutBasket || "/address"} passHref>
                      <Link>
                        <Button
                          float="right"
                          fontWeight={500}
                          variant="inverted"
                          isDisabled={isInvalidBasket}
                        >
                          {dict("ButtonGoToCheckout")}
                        </Button>
                      </Link>
                    </NextLink>
                  </Flex>
                </Flex>
              </Container>
            ) : (
              <Container py={4}>
                <Flex justifyContent="flex-end">
                  <Text>{dict("TextCartCarouselDescriptionNone")}</Text>
                </Flex>
              </Container>
            )}
          </Box>
        </Box>
      </MotionBox>
    </Box>
  );
}

export default MiniBasket;
