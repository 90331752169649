export function isCheckoutPage(kind: string): boolean {
  return resolveCheckoutStep(kind) !== null;
}

export function resolveCheckoutStep(Kind: string): number {
  switch (Kind) {
    case "CheckoutAddressPageModel":
      return 0;
    case "CheckoutDeliveryPageModel":
      return 1;
    case "CheckoutPaymentPageModel":
      return 2;
    case "CheckoutReceiptPageModel":
      return 3;
    default:
      return null;
  }
}
