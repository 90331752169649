import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";

export function getWeek(date) {
  if (!date) return;
  dayjs.extend(weekOfYear);
  const earliestWeek = dayjs(date).week();
  return earliestWeek;
}
export function getFirstDayOfWeek(week) {
  if (!week) return;
  dayjs.extend(isoWeek);
  const newDate = dayjs()
    .isoWeek(week)
    .isoWeekday(1)
    .hour(12)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toISOString();
  return newDate;
}
